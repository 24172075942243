import { PartType } from "./def";

// 主配置
export interface MainConfig {
  // 大标题
  title: string;
  // 次级标题
  title2?: string;
  // 页脚文字
  footer: Array<string>;
  // 顶部背景图片
  topBg?: string;
  // 顶部导航对应的栏目
  topNavColumn: string;
  // 底部链接对应的栏目
  footerNavColumn?: string;
}

// 在上级块中的位置选项
export interface PosItem {
  id: string | number;
  name: string;
}

// 块选择的列表
export interface PartItem {
  type: PartType;
  name: string;
  // 允许下级块
  allowChild?: Array<PartType>;
  // 下级配置类型
  childType?: string;

  // 可以配置长度  [最小长度], [最小长度, 最大长度]
  allowLen?: Array<number>;
  // 可以配置位置
  allowPos?: Array<PosItem>;
  // 不允许作为顶级块
  childOnly?: boolean;
  // 栏目设置无效
  noColumn?: boolean;
}

// 一块内容的配置
export interface PartConfig {
  // 块的类型
  type: PartType;
  // 下级块的配置
  child?: Array<PartConfig>;

  // 块对应的栏目
  columnid?: string;

  // 块标题
  title?: string;
  // 块标题2
  title2?: string;

  // 块的展示长度
  len?: number;
  // 块在上级块中的位置
  pos?: string | number;

  // 用于编辑时识别块
  _index?: number;

  // 在调用数据时需要接口中存在该key?
  newAxios?: string;
}

// 已经实现的块列表
export const partTypeList: Array<PartItem> = [
  {
    type: PartType.A,
    name: "两列分栏",
    allowChild: [PartType.B, PartType.C],
    childType: "list",
    noColumn: true,
    allowPos: [
      {
        id: 1,
        name: "左",
      },
      {
        id: 2,
        name: "右",
      },
    ],
  },
  {
    type: PartType.B,
    name: "简洁列表",
    allowLen: [1],
    childOnly: true,
  },
  {
    type: PartType.C,
    name: "图文列表",
    allowLen: [1],
    childOnly: true,
  },
  {
    type: PartType.D,
    name: "窄导航栏",
  },
  {
    type: PartType.E,
    name: "宽导航栏",
  },
  {
    type: PartType.F,
    name: "单行图片展示框",
  },
  {
    type: PartType.G,
    name: "多行图片展示框",
    allowLen: [1],
  },
  {
    type: PartType.H,
    name: "图片轮播框",
  },
  {
    type: PartType.I,
    name: "自动轮播盒子",
  },
];

// 编辑选中块时, 块调用编辑器的界面
export interface EditSelectHost {
  onSelect: (config: PartConfig) => void;
}
