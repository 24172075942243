import { AxiosPromise } from "axios";
import request from "./util";
import { ResResult2, PageResultT } from "./type";

export interface ColumnTreeResult {
  //page: null;
  //limit: null;
  //keysearchword: null;
  id: string;
  doctype: string | undefined;
  name: string;
  label: string;
  subtitle: string | undefined;
  pid: string;
  pname: string | undefined;
  pidWithChild: string | undefined;
  orgid: string | undefined;
  comments: string | undefined;
  orders: number;
  spare1: string | undefined;
  spare2: string | undefined;
  spare3: string | undefined;
  spare4: string | undefined;
  spare5: string | undefined;
  createBy: string;
  createDate: number;
  updateBy: string;
  updateDate: number;
  delFlag: number;
  showmode: string | undefined;
  children: Array<ColumnTreeResult>;
}

export function getColumnTree(
  id: string
): AxiosPromise<ResResult2<Array<ColumnTreeResult>>> {
  return request({
    url: `/cmscolumn/publicapi/trees?id=${id}`,
    method: "GET",
  });
}

export interface ColumnItemResult {
  columnId: string;
  columnIdWithChild: string | undefined;
  columnName: string;
  comments: string | undefined;
  context: string | undefined;
  createBy: string;
  createDate: number;
  delFlag: number;
  doctype: string | undefined;
  fid: string | undefined;
  fixtop: number;
  fname: string | undefined;
  id: string;
  imgfilename: string | undefined;
  imgid: string | undefined;
  keysearchword: string | undefined;
  limit: string | undefined;
  orders: number;
  orgid: string | undefined;
  page: string | undefined;
  publishdate: string | undefined;
  spare1: string | undefined;
  spare2: string | undefined;
  spare3: string | undefined;
  spare4: string | undefined;
  spare5: string | undefined;
  spare6: string | undefined;
  spare7: string | undefined;
  spare8: string | undefined;
  spare9: string | undefined;
  subtitle: string;
  title: string;
  updateBy: string | undefined;
  updateDate: number | undefined;
  linkurl: string | undefined;
  clicktimes: number | undefined;
}

export interface ColumnItemResultNewAxios {
  address: string | undefined;
  clicktimes: number | undefined;
  comesource: string | undefined;
  comments: string | undefined;
  createBy: string | undefined;
  createDate: number | undefined;
  delFlag: number | undefined;
  fixtop: string | undefined;
  hot: string | undefined;
  id: string | undefined;
  imgid: string | undefined;
  keysearchword: string | undefined;
  limit: string | undefined;
  name: string;
  orders: number | undefined;
  org1: string | undefined;
  org1name: string | undefined;
  org2: string | undefined;
  org2name: string | undefined;
  org3: string | undefined;
  org3name: string | undefined;
  orgfullname: string | undefined;
  page: string | undefined;
  productid: string | undefined;
  producttypeid: string | undefined;
  context: string | undefined;
  producttypename: string | undefined;
  publishdate: string | undefined;
  recommdesc: string | undefined;
  recommtype: string | undefined;
  salemode: string | undefined;
  sourceid: string | undefined;
  sourcename: string | undefined;
  status: number | undefined;
  updateBy: string | undefined;
  updateDate: string | undefined;
}

export function getColumnListNewAxios(
  page: number,
  limit: number
): AxiosPromise<PageResultT<ColumnItemResultNewAxios>> {
  return request({
    url: `/prdrecomm/publicapi/showList?page=${page + 1}&limit=${limit}`,
    method: "GET",
  });
}

export function getProductDetail(
  id: string
): AxiosPromise<PageResultT<ColumnItemResultNewAxios>> {
  return request({
    url: `/product/publicapi/detail?id=${id}`,
    method: "GET",
  });
}

export function getSelectByFid(fid: string): AxiosPromise {
  return request({
    url: `/sysfile/publicapi/selectByFid?fid=${fid}`,
    method: "GET",
  });
}

export function getColumnList(
  page: number,
  limit: number,
  columnid: string
): AxiosPromise<PageResultT<ColumnItemResult>> {
  return request({
    url: `/cmsdoc/publicapi/list?columnId=${columnid}&page=${
      page === 0 ? 1 : page
    }&limit=${limit}`,
    method: "GET",
  });
}

export function getColumnItemDetail(
  id: string
): AxiosPromise<ResResult2<ColumnItemResult>> {
  return request({
    url: `/cmsdoc/publicapi/detail?id=${id}`,
    method: "GET",
  });
}

export function getColumnInfo(
  id: string
): AxiosPromise<ResResult2<ColumnTreeResult>> {
  return request({
    url: `/cmscolumn/publicapi/detail?id=${id}`,
    method: "GET",
  });
}
