import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "part-column-list" }

import { computed, toRefs, Ref } from "vue";
import type { PartConfig } from "@/type";
import { PartType } from "@/type/def";
import Loading from "@/components/ui/Loading.vue";
import PartList1 from "./PartList1.vue";
import PartList2 from "./PartList2.vue";


export default _defineComponent({
  props: {
  index: {
    type: Number,
    required: true,
  },
  config: {
    type: Array,
    required: true,
  },
},
  setup(__props) {

const props = __props


const { index } = toRefs(props);
const config = toRefs(props).config as Ref<Array<PartConfig>>;

const list = computed(() =>
  config.value.map((x) => {
    const result = {
      config: x,
      // 未知类型, 显示加载中
      type: "",
    };
    switch (x.type) {
      // 简洁列表
      case PartType.B:
        result.type = "list1";
        break;
      // 图文列表
      case PartType.C:
        result.type = "list2";
        break;
    }
    return result;
  })
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (i, _i) => {
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        key: _i,
        class: "item"
      }, [
        (i.type == 'list1')
          ? (_openBlock(), _createBlock(PartList1, {
              key: 0,
              config: i.config
            }, null, 8, ["config"]))
          : (i.type == 'list2')
            ? (_openBlock(), _createBlock(PartList2, {
                key: 1,
                config: i.config
              }, null, 8, ["config"]))
            : (_openBlock(), _createBlock(Loading, {
                key: 2,
                loading: ""
              }))
      ], 512)), [
        [_vShow, _i == _unref(index)]
      ])
    }), 128))
  ]))
}
}

})