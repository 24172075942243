import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-64da3d9e")
const _hoisted_1 = { class: "edit-page" }
_popScopeId()

import {
  ref,
  inject,
  computed,
  toRefs,
  Ref,
} from "vue";
import type { PartConfig } from "@/type";
import { KEY_EDIT_SELECT_CURRENT } from "@/hook/key";
import SelectPart from "./SelectPart.vue";
import ConfigPart from "./ConfigPart.vue";
import AddPart from "./AddPart.vue";


export default _defineComponent({
  props: {
  config: {
    type: Array,
    required: true,
  },
},
  emits: ["update:config"],
  setup(__props, { emit }) {

const props = __props




const config = toRefs(props).config as Ref<Array<PartConfig>>;

const editCurrent = inject(KEY_EDIT_SELECT_CURRENT, ref(0));

const currentConfig = computed(() =>
  config.value.find((x) => x._index == editCurrent.value)
);

function setConfig(c: Array<PartConfig>) {
  emit("update:config", c);
}

function initEdit(): [Array<PartConfig>, number, PartConfig] {
  const to = Array.from(config.value);
  const item = to.find((x) => x._index == editCurrent.value) as PartConfig;
  const index = to.indexOf(item);
  return [to, index, item];
}

function addConfig(c: PartConfig) {
  let [to, index] = initEdit();
  // 当前选择了块, 在选择块下方插入
  if (index != -1) {
    to = to
      .slice(0, index + 1)
      .concat([c])
      .concat(to.slice(index + 1, to.length));
  } else {
    // 当前没有选择块, 在最下方插入
    to.push(c);
  }
  setConfig(to);
  // 插入后选中新添加的块
  if (index != -1) {
    editCurrent.value = index + 2;
  } else {
    editCurrent.value = to.length;
  }
}

function moveUp() {
  let [to, index, item] = initEdit();
  // 最上方无法上移
  if (index == -1 || index == 0) {
    return;
  }
  to = to
    .slice(0, index - 1)
    .concat([item, to[index - 1]])
    .concat(to.slice(index + 1, to.length));
  setConfig(to);
  // 移动后保持选择之前的块
  editCurrent.value -= 1;
}

function moveDown() {
  let [to, index, item] = initEdit();
  // 最下方无法下移
  if (index == -1 || index == to.length - 1) {
    return;
  }
  to = to
    .slice(0, index)
    .concat([to[index + 1], item])
    .concat(to.slice(index + 2, to.length));
  setConfig(to);
  // 移动后保持选择之前的块
  editCurrent.value += 1;
}

function delCurrent() {
  let [to, index] = initEdit();
  if (index != -1) {
    to = to.slice(0, index).concat(to.slice(index + 1, to.length));
    setConfig(to);
    // 删除之后清除选择
    editCurrent.value = 0;
  }
}

function updateConfig(c: PartConfig) {
  const [to, index] = initEdit();
  if (index != -1) {
    to[index] = c;
    setConfig(to);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SelectPart, { config: _unref(currentConfig) }, null, 8, ["config"]),
    (_unref(currentConfig) != null)
      ? (_openBlock(), _createBlock(ConfigPart, {
          key: 0,
          config: _unref(currentConfig),
          onUpdate: updateConfig,
          onMoveUp: moveUp,
          onMoveDown: moveDown,
          onDel: delCurrent
        }, null, 8, ["config"]))
      : _createCommentVNode("", true),
    _createVNode(AddPart, { onAdd: addConfig })
  ]))
}
}

})