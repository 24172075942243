import { Dayjs } from "dayjs";

export function formatDate(d: Dayjs): string {
  return d.format("YYYY-MM-DD");
}

export function formatDatetime(d: Dayjs): string {
  return d.format("YYYY-MM-DD HH:mm");
}

export function formatDatetimeS(d: Dayjs): string {
  return d.format("YYYY-MM-DD HH:mm:ss");
}
