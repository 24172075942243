import { AxiosPromise } from "axios";
import request from "./util";
import { ResResult } from "./type";

export interface CodeResult {
  data: any;
  imgdata: string;
  codeuuid: string;
}

export function getMapList(): AxiosPromise<CodeResult> {
  return request({
    url: "/netgrid/api/showList?page=1&limit=500",
    method: "post",
  });
}
