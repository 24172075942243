import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-470810eb")
const _hoisted_1 = { class: "layout-box" }
const _hoisted_2 = ["src"]
_popScopeId()

import { toRefs, Ref } from "vue";
import type { PartConfig } from "@/type";
import { useSmallScreen, useGoLink } from "@/hook";
import { usePartLoad } from "@/hook/part";
import PartBox from "@/components/ui/PartBox.vue";
import Loading from "@/components/ui/Loading.vue";
import LinkBtn from "@/components/ui/LinkBtn.vue";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props


const isSmallScreen = useSmallScreen();

const config = toRefs(props).config as Ref<PartConfig>;
const [loading, list] = usePartLoad(config, undefined, () => 20);

const goLink = useGoLink();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PartBox, {
    class: _normalizeClass(["part-nav1", { ss: _unref(isSmallScreen) }]),
    config: _unref(config)
  }, {
    default: _withCtx(() => [
      (_unref(loading))
        ? (_openBlock(), _createBlock(Loading, {
            key: 0,
            loading: ""
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (i, index) => {
          return (_openBlock(), _createBlock(LinkBtn, {
            key: index,
            onClick: ($event: any) => (_unref(goLink)(i))
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: i.img
              }, null, 8, _hoisted_2),
              _createElementVNode("span", null, _toDisplayString(i.title), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["class", "config"]))
}
}

})