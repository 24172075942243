import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "richtext-box" }
const _hoisted_2 = ["innerHTML"]

import { toRefs } from "vue";

export default _defineComponent({
  props: {
  content: {
    required: true,
  },
  fid: {
    required: true,
  },
},
  setup(__props) {

const props = __props

const { content, fid } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      innerHTML: _unref(content),
      class: "content"
    }, null, 8, _hoisted_2)
  ]))
}
}

})