export const R_OK = "0";

export interface ResResult<T> {
  retCode: string;
  retMsg: string;
  data: T | undefined;
}

export interface ResResult2<T> {
  flag: boolean;
  msg: string;
  status: number;
  data: T | undefined;
}

export interface PageResult<T> extends Array<T> {
  total: number;
}

export interface PageResultT<T> {
  code: number;
  count: number;
  data: Array<T>;
  msg: string;
  pageNum: number;
}
