<!-- PageBox

  slot: (default)

-->

<template>
  <div class="page-box">
    <slot />
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped></style>
