import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId } from "vue"

import {
  ref,
  computed,
  inject,
  watch,
  toRefs,
  onMounted,
  Ref,
} from "vue";
import type { PartConfig } from "@/type";
import { KEY_EDIT_ZOOM } from "@/hook/key";
import { useEdit } from "@/hook";
import { useEditSelect, useEditSelectCurrent } from "@/hook/edit";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props


const config = toRefs(props).config as Ref<PartConfig>;

const isEdit = useEdit();
const editHost = useEditSelect();
const current = useEditSelectCurrent();

const active = computed(() => current.value == config.value._index);

function onClick() {
  if (!isEdit.value) {
    return;
  }

  editHost.onSelect(config.value);
}

// 抵消缩放的效果
const zoom = inject(KEY_EDIT_ZOOM, ref(1));

const zoomX = computed(() => (zoom.value > 0 ? (1 / zoom.value) * 1.4 : 1));

const activeBoxStyle = computed(() => ({
  borderWidth: `${zoomX.value}px`,
}));

function sizeStyle() {
  return {
    fontSize: `${12 * zoomX.value}px`,
    height: `${20 * zoomX.value}px`,
    lineHeight: `${12 * zoomX.value}px`,
    padding: `${4 * zoomX.value}px`,
    borderRadius: `${2 * zoomX.value}px`,
  };
}
const xSizeStyle = computed(() =>
  Object.assign(sizeStyle(), {
    top: `-${23 * zoomX.value}px`,
  })
);
const ySizeStyle = computed(() =>
  Object.assign(sizeStyle(), {
    transform: `translateX(calc(100% + ${3 * zoomX.value}px)) translateY(-50%)`,
  })
);

// 获取大小
const root = ref(null);

const size = ref([0, 0]);

function updateSize() {
  const e = root.value as unknown as HTMLDivElement;
  size.value = [e.clientWidth, e.clientHeight];
}

watch(active, () => {
  if (active.value) {
    updateSize();
  }
});

// 修复自动大小
onMounted(() => {
  updateSize();
  setTimeout(updateSize, 0);
});

watch(config, () => {
  setTimeout(updateSize, 0);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref: root,
    class: _normalizeClass(["edit-select-box", { edit: _unref(isEdit), active: _unref(active) }]),
    onClick: onClick
  }, [
    _renderSlot(_ctx.$slots, "default"),
    (_unref(active))
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "active-box",
          style: _normalizeStyle(_unref(activeBoxStyle))
        }, [
          _createElementVNode("div", {
            class: "x-size",
            style: _normalizeStyle(_unref(xSizeStyle))
          }, _toDisplayString(size.value[0]) + "px", 5),
          _createElementVNode("div", {
            class: "y-size",
            style: _normalizeStyle(_unref(ySizeStyle))
          }, _toDisplayString(size.value[1]) + "px", 5)
        ], 4))
      : _createCommentVNode("", true)
  ], 2))
}
}

})