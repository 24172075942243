import { computed, watch, ComputedRef } from "vue";
// ?
import { Store } from "vuex";

// type目录下index文件夹中导出的 ThemeConfig
// 主题接口,限制主题格式
import type { ThemeConfig } from "@/type";
// 解释 : 该页面引入了所有store文件并且做了导出 : export interface State extends StateUser, StateTheme, StateEdit {}
// 但是不清楚具体用法
import { State } from "@/store";
// api目录下的 theme.ts 文件中导出的 loadTheme
// 请求 themeconfig/publicapi/detail?id=1 接口 , 获取服务器返回的主题数据 , 并且将值return至loadTheme
import { loadTheme } from "@/api/theme";
// 当前目录下的 store.ts 文件中导出的 useStore
// useStore用来实例化store
import { useStore } from "./store";

// 抽离设置样式的方法
function setCssVar(key: string, value: string): void {
  // document.documentElement.style.setProperty 为当前页面设置 base 样式
  document.documentElement.style.setProperty(key, value);
}

// 设置样式
function setTheme(theme: ThemeConfig): void {
  // DEBUG
  console.log("hook/theme::setTheme");
  console.log(theme);

  setCssVar("--color-main", theme.mainColor);
  setCssVar("--color-footer", theme.footerColor);
  setCssVar("--color-footer-text", theme.footerTextColor);
  setCssVar("--color-page", theme.pageColor);
  setCssVar("--color-page2", theme.pageColor2);
  setCssVar("--color-text", theme.textColor);
  setCssVar("--color-text2", theme.textColor2);
  setCssVar("--color-text3", theme.textColor3);
  setCssVar("--color-top-nav", theme.topNavColor);
  setCssVar("--color-top-title", theme.topTitleColor);
  setCssVar("--color-top-title2", theme.topTitleColor2);
  setCssVar("--bg-top-title", theme.topTitleBg);

  setCssVar("--width-main", theme.mainWidth + "px");
  setCssVar("--font-size-default", theme.defaultFontSize + "px");
}

// 使用样式
// 返回值格式为 : [computend,()=>{}]
export function useTheme(): [
  // useTheme返回值1 : computed
  ComputedRef<ThemeConfig>,
  // useTheme返回值2 : 函数
  (theme: ThemeConfig) => void
] {
  // 初始化sotre
  const store = useStore();

  // 获取store中的主题
  // computed<ThemeConfig> 限制格式
  // 获取store下model文件夹下的theme文件导出的getters获取的theme主题
  // 默认值 = import defaultTheme from "@/config/theme";
  const theme = computed<ThemeConfig>(() => store.getters["theme/theme"]);

  // 修改store中的主题
  function setTheme(theme: ThemeConfig) {
    store.commit("theme/SET", theme);
  }

  return [theme, setTheme];
}

// 监听store中的主题,如果发生了变化则修改本地主题
export function useThemeInit(): void {
  // 获取store中的主题
  const [theme] = useTheme();

  // 监听store中的题主,如果发生了变化则修改本地样式
  watch(theme, () => {
    setTheme(theme.value);
  });
}

// 请求接口获取主题,修改store中的主题
export async function initLoadTheme(store: Store<State>): Promise<void> {
  // 请求接口获取主题
  const theme = await loadTheme();
  // 修改store中的主题
  store.commit("theme/SET", theme);
}
