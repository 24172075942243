// 所有 enum 定义
// 文件名不能使用 enum.ts 否则会报错

// 灵活块类型
export const enum PartType {
  // 两列分栏
  A = 1,
  // 简洁列表
  B = 2,
  // 图文列表
  C = 3,
  // 窄导航栏
  D = 4,
  // 宽导航栏
  E = 5,
  // 单行图片展示框
  F = 6,
  // 多行图片展示框
  G = 7,
  // 图片轮播框
  H = 8,
  // 自动轮播盒子
  I = 9,
}
