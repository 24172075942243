import axios from "axios";

// console.log(window.location.protocol);
// console.log(process.env.VUE_APP_API_BASE_URL);
// console.log(process.env.VUE_APP_API_BASE_URL_HTTP);

// const base =
//   window.location.protocol === "http:"
//     ? process.env.VUE_APP_API_BASE_URL_HTTP
//     : process.env.VUE_APP_API_BASE_URL;

const agent = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 10e3,
});

export default agent;
