import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6c95986c")
const _hoisted_1 = { class: "layout-box" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = {
  key: 0,
  class: "right"
}
_popScopeId()

import { ref, toRefs, watchEffect, Ref } from "vue";
import { useSmallScreen } from "@/hook";
import BigBox from "./BigBox.vue";
import SmallBox from "./SmallBox.vue";


export default _defineComponent({
  props: {
  columnid: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props


const { columnid } = toRefs(props);

const isSmallScreen = useSmallScreen();

const pageSize = ref([20, 10]);

const slist: Ref<Array<string>> = ref([]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["list1", { ss: _unref(isSmallScreen) }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(BigBox, {
          columnid: _unref(columnid),
          "page-size": pageSize.value[0]
        }, null, 8, ["columnid", "page-size"])
      ]),
      (!_unref(isSmallScreen))
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slist.value, (i, index) => {
              return (_openBlock(), _createBlock(SmallBox, {
                key: index,
                columnid: i,
                "page-size": pageSize.value[1]
              }, null, 8, ["columnid", "page-size"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}
}

})