import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-57c552c0")
const _hoisted_1 = { class: "loading" }
_popScopeId()

import { toRefs } from "vue";


export default _defineComponent({
  props: {
  loading: {
    type: Boolean,
    default: false,
  },
},
  setup(__props) {

const props = __props


const { loading } = toRefs(props);

return (_ctx: any,_cache: any) => {
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ], 512)), [
    [_directive_loading, _unref(loading)]
  ])
}
}

})