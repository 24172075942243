import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { toRefs, computed, Ref } from "vue";
import type { PartConfig } from "@/type";
import { PartType } from "@/type/def";
import Loading from "@/components/ui/Loading.vue";
import PartSlide from "./PartSlide.vue";
import PartColumn from "./PartColumn.vue";
import PartList1 from "./PartList1.vue";
import PartList2 from "./PartList2.vue";
import PartNav1 from "./PartNav1.vue";
import PartNav2 from "./PartNav2.vue";
import PartPic1 from "./PartPic1.vue";
import PartPic2 from "./PartPic2.vue";
import PartPic3 from "./PartPic3.vue";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props


const config = toRefs(props).config as Ref<PartConfig>;
// console.log("partConfigItem / config.value", config.value);

// console.log("partConfigItem / config.value.type", config.value.type);

// 根据块类型选择使用的灵活块组件
const part = computed(() => {
  switch (config.value.type) {
    // 两列分栏
    case PartType.A:
      return "column";
    // 窄导航栏
    case PartType.D:
      return "nav1";
    // 宽导航栏
    case PartType.E:
      return "nav2";
    // 单行图片展示框
    case PartType.F:
      return "pic1";
    // 多行图片展示框
    case PartType.G:
      return "pic2";
    // 图片轮播框
    case PartType.H:
      return "slide";
    // 自动轮播盒子
    case PartType.I:
      return "pic3";
  }
  // 未知块类型, 显示加载框
  return "";
});
// console.log("partConfigItem / part.value", part.value);

return (_ctx: any,_cache: any) => {
  return (_unref(part) == 'slide')
    ? (_openBlock(), _createBlock(PartSlide, {
        key: 0,
        config: _unref(config)
      }, null, 8, ["config"]))
    : (_unref(part) == 'column')
      ? (_openBlock(), _createBlock(PartColumn, {
          key: 1,
          config: _unref(config)
        }, null, 8, ["config"]))
      : (_unref(part) == 'list1')
        ? (_openBlock(), _createBlock(PartList1, {
            key: 2,
            config: _unref(config)
          }, null, 8, ["config"]))
        : (_unref(part) == 'list2')
          ? (_openBlock(), _createBlock(PartList2, {
              key: 3,
              config: _unref(config)
            }, null, 8, ["config"]))
          : (_unref(part) == 'nav1')
            ? (_openBlock(), _createBlock(PartNav1, {
                key: 4,
                config: _unref(config)
              }, null, 8, ["config"]))
            : (_unref(part) == 'nav2')
              ? (_openBlock(), _createBlock(PartNav2, {
                  key: 5,
                  config: _unref(config)
                }, null, 8, ["config"]))
              : (_unref(part) == 'pic1')
                ? (_openBlock(), _createBlock(PartPic1, {
                    key: 6,
                    config: _unref(config)
                  }, null, 8, ["config"]))
                : (_unref(part) == 'pic2')
                  ? (_openBlock(), _createBlock(PartPic2, {
                      key: 7,
                      config: _unref(config)
                    }, null, 8, ["config"]))
                  : (_unref(part) == 'pic3')
                    ? (_openBlock(), _createBlock(PartPic3, {
                        key: 8,
                        config: _unref(config)
                      }, null, 8, ["config"]))
                    : (_openBlock(), _createBlock(Loading, {
                        key: 9,
                        loading: ""
                      }))
}
}

})