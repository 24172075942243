import { computed, ComputedRef } from "vue";
import { UserData } from "@/type";
import { useStore } from "./store";

export function useUser(): ComputedRef<UserData | undefined> {
  const store = useStore();
  console.log("获取登录信息", store.getters["user/userInfo"]);
  return computed<UserData | undefined>(() => store.getters["user/userInfo"]);
}

export function useUserLogin(): ComputedRef<boolean> {
  const store = useStore();
  return computed(() => store.getters["user/isLogin"]);
}
