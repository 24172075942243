import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId } from "vue"

import { toRefs, Ref } from "vue";
import type { PartConfig } from "@/type";
import PageBox from "@/components/layout/PageBox.vue";
import TopNav from "@/components/base/TopNav.vue";
import Footer from "@/components/base/Footer.vue";
import PartConfigAll from "@/components/part/PartConfigAll.vue";


export default _defineComponent({
  props: {
  config: {
    type: Array,
    required: true,
  },
},
  emits: ["edit"],
  setup(__props, { emit }) {

const props = __props




// return出config
const config = toRefs(props).config as Ref<Array<PartConfig>>;

function onEdit() {
  emit("edit");
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageBox, { class: "flex-page" }, {
    default: _withCtx(() => [
      _createVNode(TopNav, {
        "enable-edit": "",
        onEdit: onEdit
      }),
      _createVNode(PartConfigAll, { config: _unref(config) }, null, 8, ["config"]),
      _createVNode(Footer)
    ]),
    _: 1
  }))
}
}

})