import dayjs from "dayjs";
import { Column, ColumnItem } from "@/type";
import { PageResult } from "./backend/type";
import { formatDate, formatDatetime } from "@/util";
import {
  getColumnTree,
  getColumnList as getList,
  getColumnItemDetail,
  getColumnInfo as getCinfo,
  ColumnTreeResult,
} from "./backend/cms";
import { getImgUrl } from "./backend/img";

// 获取所有栏目列表
export async function getColumnList(): Promise<Array<Column>> {
  function mapData(d: Array<ColumnTreeResult>): Array<Column> {
    return d.map((x) => ({
      id: x.id,
      name: x.name,
      type: x.showmode,
      children: x.children != null ? mapData(x.children) : undefined,
    }));
  }

  // id = ""  表示所有栏目
  const { data } = await getColumnTree("");
  if (data.data != null) {
    return mapData(data.data);
  }
  return [];
}

// 获取一个栏目的信息
export async function getColumnInfo(columnid: string): Promise<Column> {
  // 根据 columnid 获取 columnid 详情
  const { data } = await getCinfo(columnid);
  //   console.log("api - column - getColumnInfo - 形参 / 点击更多跳转", data.data);
  //   console.log("农家特产原 [showmode] = 4");
  if (data.data != null) {
    const d = data.data;
    return {
      id: d.id,
      name: d.name,
      type: d.showmode,
    };
  }
  // 未知栏目
  return {
    id: columnid,
    name: "",
  };
}

// 获取一个栏目的列表
// 顶部底部请求接口
export async function getArticleList(
  columnid: string,
  page = 0,
  //   测试是否是顶部底部请求接口
  //   limit = 89999
  limit = 10
): Promise<PageResult<ColumnItem>> {
  const { data } = await getList(page, limit, columnid);
  if (data.data != null) {
    const result = data.data.map((x) => ({
      id: x.id,
      columnid: x.columnId,
      title: x.title,
      title2: x.subtitle,
      desc: x.comments,
      img: x.imgid != null ? getImgUrl(x.imgid) : undefined,
      time: formatDate(dayjs(x.createDate)),
      publishdate:
        x.publishdate != null ? formatDate(dayjs(x.publishdate)) : undefined,
      //url: ,
      toColumn: x.linkurl,
      content: x.context,
      fix: x.fid,
      countRead: x.clicktimes != null ? x.clicktimes : 0,
    })) as PageResult<ColumnItem>;

    result.total = data.count;
    return result;
  }

  const result = [] as unknown as PageResult<ColumnItem>;
  result.total = 0;
  return result;
}

// 获取一篇文章信息
export async function getArticle(id: string): Promise<ColumnItem> {
  const { data } = await getColumnItemDetail(id);
  if (data.data != null) {
    const d = data.data;
    console.log(d);
    return {
      id: d.id,
      columnid: d.columnId,
      title: d.title,
      title2: d.subtitle,
      desc: d.comments,
      img: d.imgid != null ? getImgUrl(d.imgid) : undefined,
      time: formatDatetime(dayjs(d.createDate)),
      publishdate:
        d.publishdate != null
          ? formatDatetime(dayjs(d.publishdate))
          : undefined,
      // url: "",
      toColumn: d.linkurl,
      content: d.context,
      fix: d.fid,
      countRead: d.clicktimes != null ? d.clicktimes : 0,
    };
  }
  return {
    title: "",
    countRead: 0,
  };
}
