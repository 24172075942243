import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"


import { useEdit } from "@/hook";


export default _defineComponent({
  emits: ["click"],
  setup(__props, { emit }) {



// 检查是否是编辑模式, 如果是, 禁用点击
const isEdit = useEdit();

function onClick() {
  if (!isEdit.value) {
    emit("click");
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["link-btn", { edit: _unref(isEdit) }]),
    onClick: onClick
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})