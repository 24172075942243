import { ref, onMounted, Ref } from "vue";
import { useStore } from "./store";
// 请求接口获取主题,修改store中的主题
import { initLoadTheme } from "./theme";
import { initLoadMainConfig } from "./edit";

export function useLoading(): Ref<boolean> {
  const loading = ref(true);
  const store = useStore();

  onMounted(async () => {
    // 并行初始化加载
    // initLoadTheme 请求接口获取主题,修改store中的主题
    // initLoadMainConfig 请求接口获取页头页脚文字/页头背景图片,修改store中的页头页脚文字/页头背景图片
    await Promise.all([initLoadTheme(store), initLoadMainConfig(store)]);
    // 加载完成
    loading.value = false;
  });

  return loading;
}
