import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d6d25c88")
const _hoisted_1 = { class: "link-box" }
const _hoisted_2 = { class: "footTextDefaultClass" }
const _hoisted_3 = { class: "footTextItem" }
_popScopeId()

import { ref, computed, onMounted, Ref } from "vue";
import type { ColumnItem } from "@/type";
import { getArticleList } from "@/api/column";
import { useSmallScreen, useMainConfig, useGoLink } from "@/hook";
import LinkBtn from "@/components/ui/LinkBtn.vue";


export default _defineComponent({
  setup(__props) {

const isSmallScreen = useSmallScreen();
const config = useMainConfig();

const linkList: Ref<Array<ColumnItem>> = ref([]);

const footerText = computed(() => config.value.footer);

function goRecordLink() {
  window.open("https://beian.miit.gov.cn/#/Integrated/index");
}

onMounted(async () => {
  const columnid = config.value.footerNavColumn;
  if (columnid != null) {
    linkList.value = await getArticleList(columnid);
  }
});

const goLink = useGoLink();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["footer", { ss: _unref(isSmallScreen) }])
  }, [
    _createElementVNode("p", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(linkList.value, (i, index) => {
        return (_openBlock(), _createBlock(LinkBtn, {
          key: index,
          onClick: ($event: any) => (_unref(goLink)(i))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(i.title), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(footerText)[0]), 1),
      _createElementVNode("p", {
        class: "footTextItem footTextItemClick",
        onClick: goRecordLink
      }, _toDisplayString(_unref(footerText)[1]), 1)
    ]),
    _createElementVNode("p", null, _toDisplayString(_unref(footerText)[2]), 1)
  ], 2))
}
}

})