import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-36b17286")
const _hoisted_1 = { class: "left" }
const _hoisted_2 = { class: "right" }
_popScopeId()

import { toRefs, Ref } from "vue";
import type { PartConfig } from "@/type";
import { useSmallScreen } from "@/hook";
import LinkBtn from "@/components/ui/LinkBtn.vue";
import BtnMore from "@/components/ui/BtnMore.vue";


export default _defineComponent({
  props: {
  config: {
    type: Array,
    required: true,
  },
  // v-model
  modelValue: {
    type: Number,
    required: true,
  },
},
  emits: ["update:modelValue", "more"],
  setup(__props, { emit }) {

const props = __props




const isSmallScreen = useSmallScreen();

const { modelValue } = toRefs(props);
const config = toRefs(props).config as Ref<Array<PartConfig>>;

function setIndex(index: number) {
  emit("update:modelValue", index);
}

function onMore() {
  emit("more");
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["part-list-title", { ss: _unref(isSmallScreen) }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(config), (i, index) => {
        return (_openBlock(), _createBlock(LinkBtn, {
          key: index,
          class: _normalizeClass({ active: _unref(modelValue) == index }),
          onClick: ($event: any) => (setIndex(index))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(i.title), 1)
          ]),
          _: 2
        }, 1032, ["class", "onClick"]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(BtnMore, { onClick: onMore })
    ])
  ], 2))
}
}

})