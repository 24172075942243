import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "part-config-all" }

import { toRefs, Ref } from "vue";
import type { PartConfig } from "@/type";
import Loading from "@/components/ui/Loading.vue";
import PartConfigItem from "./PartConfigItem.vue";


export default _defineComponent({
  props: {
  config: {
    type: Array,
    required: true,
  },
},
  setup(__props) {

const props = __props


const config = toRefs(props).config as Ref<Array<PartConfig>>;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(config).length > 0)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_unref(config), (i, index) => {
          return (_openBlock(), _createBlock(PartConfigItem, {
            key: index,
            config: i
          }, null, 8, ["config"]))
        }), 128))
      : (_openBlock(), _createBlock(Loading, {
          key: 1,
          loading: ""
        }))
  ]))
}
}

})