import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "video-box",
  style: {"text-align":"center"}
}
const _hoisted_2 = ["src"]

import { toRefs } from "vue";

export default _defineComponent({
  props: {
  fid: {
    required: true,
  },
},
  setup(__props) {

const props = __props

const { fid } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("video", {
      autoplay: "",
      controls: "",
      width: "800",
      height: "400",
      src: 
        'https://www.feicheng.org.cn:7081/sysfile/publicapi/showVideoById?id=' +
        _unref(fid)
      
    }, null, 8, _hoisted_2)
  ]))
}
}

})