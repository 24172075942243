import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-facc875e")
const _hoisted_1 = { class: "layout-box" }
_popScopeId()

import { toRefs, watchEffect } from "vue";
import { useMainConfig } from "@/hook";
import { setPageTitle } from "@/util";
import PageBox from "@/components/layout/PageBox.vue";
import TopNav from "@/components/base/TopNav.vue";
import Footer from "@/components/base/Footer.vue";
import List1 from "./list1/index.vue";
import List2 from "./list2/index.vue";
import List4 from "./list4/index.vue";


export default _defineComponent({
  props: {
  columnid: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "1",
  },
},
  setup(__props) {

const props = __props


const { columnid, type } = toRefs(props);

const mainConfig = useMainConfig();

watchEffect(() => {
  setPageTitle(mainConfig.value.title);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageBox, { class: "page" }, {
    default: _withCtx(() => [
      _createVNode(TopNav),
      _createElementVNode("div", _hoisted_1, [
        (_unref(type) == '1')
          ? (_openBlock(), _createBlock(List1, {
              key: 0,
              columnid: _unref(columnid)
            }, null, 8, ["columnid"]))
          : _createCommentVNode("", true),
        (_unref(type) == '2')
          ? (_openBlock(), _createBlock(List2, {
              key: 1,
              columnid: _unref(columnid)
            }, null, 8, ["columnid"]))
          : _createCommentVNode("", true),
        (_unref(type) == '4')
          ? (_openBlock(), _createBlock(List4, {
              key: 2,
              columnid: _unref(columnid)
            }, null, 8, ["columnid"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(Footer)
    ]),
    _: 1
  }))
}
}

})