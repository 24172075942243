import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ceec2a0e")
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "minW layoutBox"
}
const _hoisted_3 = {
  key: 0,
  class: "lay_item_Tsubtitle"
}
const _hoisted_4 = { class: "lay_item_text" }
const _hoisted_5 = {
  key: 1,
  class: "lay_item_text mt"
}
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "layoutBgc"
}
const _hoisted_8 = { class: "minW layoutBox" }
const _hoisted_9 = { class: "lay_imgBox_l" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "lay_TitleBox" }
const _hoisted_12 = { class: "lay_item_title" }
const _hoisted_13 = { class: "lay_item_text" }
_popScopeId()




export default _defineComponent({
  props: {
  list: {
    type: Array,
    required: true,
  },
},
  setup(__props) {

const props = __props


return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.list, (item, index) => {
    return (_openBlock(), _createElementBlock("div", {
      key: index,
      id: item.subtitle ? '' : 'Anchor' + index
    }, [
      (item.position)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass(
            item.subtitle
              ? 'lay_TitleBox lay_item_subtitle_TitleBox'
              : 'lay_TitleBox'
          )
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(
              item.subtitle ? 'lay_item_subtitle_title' : 'lay_item_title'
            )
              }, _toDisplayString(item.title), 3),
              (item.subtitle)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(item.subtitle), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, _toDisplayString(item.text1), 1),
              (item.subtitle)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(item.text2), 1))
                : _createCommentVNode("", true)
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(item.subtitle ? '' : 'lay_imgBox_r')
            }, [
              _createElementVNode("img", {
                src: item.img,
                class: _normalizeClass(item.subtitle ? 'lay_item_subtitle_img' : 'lay_item_img')
              }, null, 10, _hoisted_6)
            ], 2)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("img", {
                  src: item.img,
                  class: "lay_item_img"
                }, null, 8, _hoisted_10)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(item.title), 1),
                _createElementVNode("div", _hoisted_13, _toDisplayString(item.text1), 1)
              ])
            ])
          ]))
    ], 8, _hoisted_1))
  }), 128))
}
}

})