import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId } from "vue"

import { ref, watchEffect, toRefs, Ref } from "vue";
import { useRouter } from "vue-router";
// type->index->column->ColumnItem
import type { PartConfig } from "@/type";

// hook->index->edit
// store.getters["edit/main"]
// ->type-index->edit
import { useMainConfig } from "@/hook";
import { setPageTitle } from "@/util";
// /themeconfig/publicapi/detail?id=
// config 的数据都在 JSON.parse(d.homeConfigData) as Array<PartConfig>
import { loadPartList } from "@/api/edit";
// /cmsdoc/publicapi/list?columnId=${columnid}
import { getColumnInfo } from "@/api/column";
import FlexPage from "./FlexPage.vue";

// 设置默认值

export default _defineComponent({
  props: {
  columnid: {
    type: String,
    // 默认 "" 加载首页
    default: "",
  },
},
  setup(__props) {

const props = __props


const { columnid } = toRefs(props);
// columnid = ''

const config: Ref<Array<PartConfig>> = ref([]);

async function loadData() {
  // DEBUG
  if (columnid.value === "") {
    console.log("load columnid = " + "默认值");
  }

  getColumnInfo(columnid.value).then((info) => {
    //setPageTitle(info.name);
  });

  // 获取大致列表,获取列表对应的columnid
  config.value = await loadPartList(columnid.value);
  //
  console.log("Homde - index / 首页列表", config.value);
}

const mainConfig = useMainConfig();

//
watchEffect(() => {
  setPageTitle(mainConfig.value.title);
  //
  loadData();
});

const router = useRouter();

function onEdit() {
  router.push(`/edit?columnid=${columnid.value}`);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FlexPage, {
    class: "page",
    config: config.value,
    onEdit: onEdit
  }, null, 8, ["config"]))
}
}

})