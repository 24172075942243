import { ref, watch, onMounted, Ref } from "vue";
import type { PartConfig, ColumnItem } from "@/type";
import { PageResult } from "@/api/backend/type";
import { getArticleList } from "@/api/column";

export function usePartLoad(
  config: Ref<PartConfig>,
  slice?: (list: Array<ColumnItem>, len: number) => Array<ColumnItem>,
  // 请求数据的长度
  getLen?: () => number
): [Ref<boolean>, Ref<Array<ColumnItem>>] {
  const loading = ref(true);
  const list: Ref<Array<ColumnItem>> = ref([]);

  async function loadData() {
    const { columnid } = config.value;
    if (columnid != null) {
      const len = config.value.len != null ? config.value.len : 1;

      loading.value = true;
      const loadLen = getLen != null ? getLen() : len;
      let raw = await getArticleList(columnid, 0, loadLen);
      if (slice != null) {
        raw = slice(raw, len) as PageResult<ColumnItem>;
        raw.total = raw.length;
      }
      list.value = raw;
      loading.value = false;
    }
  }

  onMounted(loadData);
  watch(config, loadData);

  return [loading, list];
}
