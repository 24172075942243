import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId } from "vue"

import { toRefs, Ref, ref } from "vue";
import type { PartConfig } from "@/type";
// 跳转路由
import { useGoList } from "@/hook";
import { usePartLoad } from "@/hook/part";
import PartBox from "@/components/ui/PartBox.vue";
// 标题左侧 : 农家特产_FARM PRODUCTS
import PartTitle from "@/components/ui/PartTitle.vue";
import Loading from "@/components/ui/Loading.vue";
// 标题右侧 : +更多
import BtnMore from "@/components/ui/BtnMore.vue";
import PicList3 from "@/components/ui/PicList3.vue";
//
import { getColumnListNewAxios } from "@/api/backend/cms";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props

const loading = ref();
const list = ref(undefined);

// config包含 len 参数
const config = toRefs(props).config as Ref<PartConfig>;
// console.log("partPic3 config", config.value);

// console.log("轮播图执行", list.value, config, config.value);
[loading.value, list.value] = usePartLoad(
  config,
  (list, len) => {
    // console.log("list, len", list, len);
    return list.slice(0, len * 999);
  },
  // 初始值为3 , 每行显示3个 , 所以 3 * 3
  () => (config.value.len != null ? 999 : 3)
);

const goList = useGoList();

async function showMore() {
  if (config.value.columnid != null) {
    goList(config.value.columnid);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PartBox, {
    class: "part-pic3",
    config: _unref(config)
  }, {
    default: _withCtx(() => [
      _createVNode(PartTitle, {
        title: _unref(config).title,
        title2: _unref(config).title2
      }, {
        default: _withCtx(() => [
          _createVNode(BtnMore, { onClick: showMore })
        ]),
        _: 1
      }, 8, ["title", "title2"]),
      (loading.value.value)
        ? (_openBlock(), _createBlock(Loading, {
            key: 0,
            loading: ""
          }))
        : _createCommentVNode("", true),
      (list.value !== undefined)
        ? (_openBlock(), _createBlock(PicList3, {
            key: 1,
            list: list.value
          }, null, 8, ["list"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["config"]))
}
}

})