import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-721b3c6a")
const _hoisted_1 = { class: "layout-box" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "right" }
_popScopeId()

import { toRefs, ref, computed, Ref } from "vue";
import type { PartConfig } from "@/type";
import { useSmallScreen, useGoList } from "@/hook";
import PartBox from "@/components/ui/PartBox.vue";
import PartTitle from "@/components/ui/PartTitle.vue";
import PartListTitle from "@/components/ui/PartListTitle.vue";
import PartColumnList from "./PartColumnList.vue";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props


const isSmallScreen = useSmallScreen();

const config = toRefs(props).config as Ref<PartConfig>;

function filterChild(config: PartConfig, pos: number): Array<PartConfig> {
  if (config.child != null) {
    return config.child.filter((x) => x.pos == pos);
  }
  return [];
}

const leftConfig = computed(() => filterChild(config.value, 1));
const rightConfig = computed(() => filterChild(config.value, 2));

const leftIndex = ref(0);
const rightIndex = ref(0);

const goList = useGoList();

function onMoreLeft() {
  const i = leftConfig.value[leftIndex.value];
  if (i.columnid != null) {
    goList(i.columnid);
  }
}

function onMoreRight() {
  const i = rightConfig.value[rightIndex.value];
  if (i.columnid != null) {
    goList(i.columnid);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PartBox, {
    class: _normalizeClass(["part-column", { ss: _unref(isSmallScreen) }]),
    config: _unref(config)
  }, {
    default: _withCtx(() => [
      _createVNode(PartTitle, {
        title: _unref(config).title,
        title2: _unref(config).title2
      }, null, 8, ["title", "title2"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(PartListTitle, {
            modelValue: leftIndex.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (leftIndex.value = $event)),
            config: _unref(leftConfig),
            onMore: onMoreLeft
          }, null, 8, ["modelValue", "config"]),
          _createVNode(PartColumnList, {
            index: leftIndex.value,
            config: _unref(leftConfig)
          }, null, 8, ["index", "config"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(PartListTitle, {
            modelValue: rightIndex.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (rightIndex.value = $event)),
            config: _unref(rightConfig),
            onMore: onMoreRight
          }, null, 8, ["modelValue", "config"]),
          _createVNode(PartColumnList, {
            index: rightIndex.value,
            config: _unref(rightConfig)
          }, null, 8, ["index", "config"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["class", "config"]))
}
}

})