import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5827a0ae")
const _hoisted_1 = { class: "layout-box" }
const _hoisted_2 = { class: "img-box" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text" }
_popScopeId()

import { toRefs, Ref } from "vue";
import type { PartConfig } from "@/type";
import { useSmallScreen, useGoLink, useGoList } from "@/hook";
import { usePartLoad } from "@/hook/part";
import PartBox from "@/components/ui/PartBox.vue";
import PartTitle from "@/components/ui/PartTitle.vue";
import Loading from "@/components/ui/Loading.vue";
import BtnMore from "@/components/ui/BtnMore.vue";
import LinkBtn from "@/components/ui/LinkBtn.vue";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props


const isSmallScreen = useSmallScreen();

const config = toRefs(props).config as Ref<PartConfig>;
const [loading, list] = usePartLoad(
  config,
  (list) => {
    // 仅显示 3 张
    return list.slice(0, 3);
  },
  () => 3
);

const goList = useGoList();

async function showMore() {
  if (config.value.columnid != null) {
    goList(config.value.columnid);
  }
}

const goLink = useGoLink();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PartBox, {
    class: _normalizeClass(["part-pic1", { ss: _unref(isSmallScreen) }]),
    config: _unref(config)
  }, {
    default: _withCtx(() => [
      _createVNode(PartTitle, {
        title: _unref(config).title,
        title2: _unref(config).title2
      }, {
        default: _withCtx(() => [
          _createVNode(BtnMore, { onClick: showMore })
        ]),
        _: 1
      }, 8, ["title", "title2"]),
      (_unref(loading))
        ? (_openBlock(), _createBlock(Loading, {
            key: 0,
            loading: ""
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (i, index) => {
          return (_openBlock(), _createBlock(LinkBtn, {
            key: index,
            onClick: ($event: any) => (_unref(goLink)(i))
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: i.img
                }, null, 8, _hoisted_3),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("span", null, _toDisplayString(i.title), 1)
                ])
              ])
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["class", "config"]))
}
}

})