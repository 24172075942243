import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  ref,
  computed,
  toRefs,
  onMounted,
  Ref,
} from "vue";
// element-plus
import ElCascader from "element-plus/lib/components/cascader";
import "element-plus/theme-chalk/el-cascader.css";

import type { Column } from "@/type";
import { getColumnList } from "@/api/column";


export default _defineComponent({
  props: {
  size: {
    type: String,
    default: null,
  },
  // v-model
  modelValue: {
    type: [String, Number],
    required: true,
  },
},
  emits: ["update:model-value"],
  setup(__props, { emit }) {

const props = __props




const { size, modelValue } = toRefs(props);

function onUpdate(v: string | number) {
  emit("update:model-value", v);
}

// 所有栏目列表
const columnList: Ref<Array<Column>> = ref([]);

onMounted(async () => {
  columnList.value = await getColumnList();
});

const p = computed(() => ({
  checkStrictly: true,
  emitPath: false,
  label: "name",
  value: "id",
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(ElCascader), {
    class: "select-column",
    options: columnList.value,
    props: _unref(p),
    size: _unref(size),
    placeholder: "请选择栏目",
    clearable: "",
    "show-all-levels": false,
    "model-value": _unref(modelValue),
    "onUpdate:modelValue": onUpdate
  }, null, 8, ["options", "props", "size", "model-value"]))
}
}

})