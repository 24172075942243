import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-15e52e7d")
const _hoisted_1 = { class: "page-editor" }
_popScopeId()

import {
  ref,
  computed,
  provide,
  toRefs,
  Ref,
} from "vue";
import type { PartConfig, EditSelectHost } from "@/type";
import { KEY_EDIT_SELECT, KEY_EDIT_SELECT_CURRENT } from "@/hook/key";
import { useTheme } from "@/hook";
import { copyToClip } from "@/util/clipboard";
import { PAGE_WIDTH_PC } from "./def";
import FlexPage from "@/views/home/FlexPage.vue";
import ViewBox from "./ViewBox.vue";
import EditBox from "./EditBox.vue";


export default _defineComponent({
  props: {
  config: {
    type: Array,
    required: true,
  },
},
  emits: ["update:config", "save", "cancel"],
  setup(__props, { emit }) {

const props = __props




const config = toRefs(props).config as Ref<Array<PartConfig>>;

const pageWidth = computed(() => PAGE_WIDTH_PC);

function onSave() {
  emit("save");
}

function onCancel() {
  emit("cancel");
}

// 复制主题数据
const [theme] = useTheme();

function onCopyConfig() {
  // DEBUG
  console.log("PageEditor::onCopyConfig()");

  const data = Object.assign({}, theme.value);
  const t = JSON.stringify(data, null, "  ");
  console.log(t);

  copyToClip(t);
}

// 增加 _index 的配置
const configWithIndex = computed(() => {
  const result = config.value.map((x) => Object.assign({}, x));
  for (let i = 0; i < result.length; i += 1) {
    result[i]._index = i + 1;
  }
  return result;
});

// 编辑时当前选中的块: 0 表示没有选中
const editCurrent = ref(0);

provide(KEY_EDIT_SELECT_CURRENT, editCurrent);

const editHost: EditSelectHost = {
  onSelect(config: PartConfig) {
    if (config._index != null) {
      editCurrent.value = config._index;
    } else {
      editCurrent.value = 0;
    }
  },
};

provide(KEY_EDIT_SELECT, editHost);

function setConfig(c: Array<PartConfig>) {
  emit("update:config", c);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(ViewBox, { "page-width": _unref(pageWidth) }, {
      default: _withCtx(() => [
        _createVNode(FlexPage, { config: _unref(configWithIndex) }, null, 8, ["config"])
      ]),
      _: 1
    }, 8, ["page-width"]),
    _createVNode(EditBox, {
      "page-width": _unref(pageWidth),
      config: _unref(configWithIndex),
      "onUpdate:config": setConfig,
      onSave: onSave,
      onCancel: onCancel,
      onCopyConfig: onCopyConfig
    }, null, 8, ["page-width", "config"])
  ]))
}
}

})