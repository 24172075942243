import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-2a5e5354")
const _hoisted_1 = { class: "left" }
const _hoisted_2 = { class: "title2" }
const _hoisted_3 = { class: "right" }
_popScopeId()

import { toRefs } from "vue";
import { useSmallScreen } from "@/hook";


export default _defineComponent({
  props: {
  title: {
    type: String,
    required: true,
  },
  title2: {
    type: String,
    default: null,
  },
},
  setup(__props) {

const props = __props


const isSmallScreen = useSmallScreen();

const { title, title2 } = toRefs(props);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["part-title", { ss: _unref(isSmallScreen) }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", null, _toDisplayString(_unref(title)), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(title2)), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
}

})