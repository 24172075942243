// 内置主题
import type { ThemeConfig } from "@/type";

// 默认主题
const themeRed: ThemeConfig = {
  name: "活力红",
  mainColor: "#c4120d",

  footerColor: "#999999",
  footerTextColor: "#ffffff",

  pageColor: "#ffffff",
  pageColor2: "#e4e4e4",

  textColor: "#000000",
  textColor2: "#999999",
  textColor3: "#333333",

  topNavColor: "#ad110c",
  topTitleColor: "#de3d20",
  topTitleColor2: "#525252",
  topTitleBg: "linear-gradient(0deg, #7d0b08 0%, #de3d20 100%)",

  mainWidth: 920,
  defaultFontSize: 16,
};

// 测试主题
// const themeGreen: ThemeConfig = {
//   name: "清新绿",
//   mainColor: "#48c6a3",
//   footerColor: "#48c6a3",
//   footerTextColor: "#ffffff",
//   pageColor: "#ffffff",
//   pageColor2: "#e4e4e4",
//   textColor: "#000000",
//   textColor2: "#999999",
//   textColor3: "#333333",
//   topNavColor: "#48c6a3",
//   topTitleColor: "#48c6a3",
//   topTitleColor2: "#525252",
//   topTitleBg: "#48c6a3",
//   mainWidth: 1200,
//   defaultFontSize: 16,
// };
// const themeBlue: ThemeConfig = {
//   name: "深邃蓝",
//   mainColor: "#1e9fff",
//   footerColor: "#1e9fff",
//   footerTextColor: "#ffffff",
//   pageColor: "#ffffff",
//   pageColor2: "#e4e4e4",
//   textColor: "#000000",
//   textColor2: "#999999",
//   textColor3: "#333333",
//   topNavColor: "#1e9fff",
//   topTitleColor: "#1e9fff",
//   topTitleColor2: "#525252",
//   topTitleBg: "#1e9fff",
//   mainWidth: 1200,
//   defaultFontSize: 16,
// };
// const themePurple: ThemeConfig = {
//   name: "调皮紫",
//   mainColor: "#ba68c8",
//   footerColor: "#e1bee7",
//   footerTextColor: "#ffffff",
//   pageColor: "#ffffff",
//   pageColor2: "#f3e5f5",
//   textColor: "#000000",
//   textColor2: "#f3e5f5",
//   textColor3: "#333333",
//   topNavColor: "#9c27b0",
//   topTitleColor: "#ab47bc",
//   topTitleColor2: "#f3e5f5",
//   topTitleBg: "#ab47bc",
//   mainWidth: 1200,
//   defaultFontSize: 18,
// };

// UI 设计师定制主题

const theme1: ThemeConfig = {
  name: "清新绿",
  mainColor: "#48C6A3",
  footerColor: "#666666",
  footerTextColor: "#ffffff",
  pageColor: "#ffffff",
  pageColor2: "#e4e4e4",
  textColor: "#000000",
  textColor2: "#999999",
  textColor3: "#333333",
  topNavColor: "#48C6A3",
  topTitleColor: "#de3d20",
  topTitleColor2: "#525252",
  topTitleBg: "linear-gradient(0deg, #e17933 0%, #eab11b 100%)",
  mainWidth: 920,
  defaultFontSize: 16,
};

const theme2: ThemeConfig = {
  name: "天空蓝",
  mainColor: "#48a3dc",
  footerColor: "#666666",
  footerTextColor: "#ffffff",
  pageColor: "#ffffff",
  pageColor2: "#e4e4e4",
  textColor: "#000000",
  textColor2: "#999999",
  textColor3: "#333333",
  topNavColor: "#48a3dc",
  topTitleColor: "#de3d20",
  topTitleColor2: "#525252",
  topTitleBg: "linear-gradient(0deg, #e07535 0%, #ebb51a 100%)",
  mainWidth: 920,
  defaultFontSize: 16,
};

// 内置主题列表
export const themeList: Array<ThemeConfig> = [themeRed, theme1, theme2];

// 默认主题
export default themeRed;
