import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId } from "vue"

import { toRefs, Ref, ref } from "vue";
import type { PartConfig } from "@/type";
// 跳转路由
import { useGoList } from "@/hook";
import { usePartLoad } from "@/hook/part";
import PartBox from "@/components/ui/PartBox.vue";
// 标题左侧 : 农家特产_FARM PRODUCTS
import PartTitle from "@/components/ui/PartTitle.vue";
import Loading from "@/components/ui/Loading.vue";
// 标题右侧 : +更多
import BtnMore from "@/components/ui/BtnMore.vue";
import PicList from "@/components/ui/PicList.vue";
import PicList2 from "@/components/ui/PicList2.vue";
// =.=
import { getColumnListNewAxios } from "@/api/backend/cms";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props

const loading = ref();
const list = ref();

// config包含 len 参数
const config = toRefs(props).config as Ref<PartConfig>;
console.log("partPic2 config", config.value);

// 特色产品模块执行此处if
if (config.value.newAxios !== undefined) {
  loading.value = false;
  getColumnListNewAxios(
    0,
    config.value.len != null ? config.value.len * 3 : 3
  ).then((res) => {
    // console.log("1.获取到的特色商品新数据", res);
    list.value = res.data.data.map((item) => ({
      id: item.id,
      title: item.name,
      imgId: item.imgid,
      clicktimes: item.clicktimes,
      publishdate: item.publishdate,
      context: item.context,
      producttypename: item.producttypename,
      salemode: item.salemode,
      productid: item.productid,
    }));
    // console.log("2.传递给子组件的特色商品新数据", list.value);
  });
  //   村级网点建设,镇级网点建设模块执行else
} else {
  //   console.log("eles执行", list.value, config, config.value);
  [loading.value, list.value] = usePartLoad(
    config,
    (list, len) => {
      //   console.log("list, len", list, len);
      return list.slice(0, len * 3);
    },
    // 初始值为3 , 每行显示3个 , 所以 3 * 3
    () => (config.value.len != null ? 3 : 3)
  );
}

// console.log("partPic2 config", config);
// console.log("partPic2 list", list);

const goList = useGoList();

async function showMore() {
  if (config.value.columnid != null) {
    // =-=
    // 农家特产type值为4
    goList(config.value.columnid);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PartBox, {
    class: "part-pic2",
    config: _unref(config)
  }, {
    default: _withCtx(() => [
      _createVNode(PartTitle, {
        title: _unref(config).title,
        title2: _unref(config).title2
      }, {
        default: _withCtx(() => [
          _createVNode(BtnMore, { onClick: showMore })
        ]),
        _: 1
      }, 8, ["title", "title2"]),
      (loading.value.value)
        ? (_openBlock(), _createBlock(Loading, {
            key: 0,
            loading: ""
          }))
        : _createCommentVNode("", true),
      (_unref(config).newAxios === undefined)
        ? (_openBlock(), _createBlock(PicList, {
            key: 1,
            list: list.value
          }, null, 8, ["list"]))
        : _createCommentVNode("", true),
      (_unref(config).newAxios !== undefined && list.value !== undefined)
        ? (_openBlock(), _createBlock(PicList2, {
            key: 2,
            list: list.value
          }, null, 8, ["list"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["config"]))
}
}

})