// 加载编辑页面的数据
import { MainConfig, PartConfig } from "@/type";
import {
  getThemeConfig,
  saveThemeConfig,
  ThemeConfigData,
} from "./backend/config";
import { DEFAULT_CONFIG_ID } from "./config";

export async function loadMainConfig(): Promise<MainConfig> {
  const { data } = await getThemeConfig(DEFAULT_CONFIG_ID);
  if (data.data != null) {
    const d = data.data;
    return {
      title: d.title,
      title2: d.title2,
      footer: d.footer.split(","),
      topBg: d.topBg,
      topNavColumn: d.topNavColumn,
      footerNavColumn: d.footerNavColumn,
    };
  }
  return {
    title: "",
    footer: [],
    topNavColumn: "",
  };
}

export async function loadPartList(id: string): Promise<Array<PartConfig>> {
  if (id == "") {
    id = DEFAULT_CONFIG_ID;
  }
  const { data } = await getThemeConfig(id);
  if (data.data != null) {
    const d = data.data;
    return JSON.parse(d.homeConfigData) as Array<PartConfig>;
  }
  // 空白页
  return [];
}

export async function saveConfig(config: Array<PartConfig>): Promise<void> {
  const id = DEFAULT_CONFIG_ID;
  const data = JSON.stringify(config);
  await saveThemeConfig({
    id,
    homeConfigData: data,
  } as ThemeConfigData);
}
