import { createRouter, createWebHashHistory } from "vue-router";
// routes是路由表
import routes from "./routes";

const router = createRouter({
  // 创建 Hash 模式路由
  history: createWebHashHistory(),
  routes,
});

export default router;
