import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-n" }

import { toRefs } from "vue";
// element-plus
import ElPagination from "element-plus/lib/components/pagination";
import "element-plus/theme-chalk/el-pagination.css";


export default _defineComponent({
  props: {
  total: {
    type: Number,
    required: true,
  },
  pageSize: {
    type: Number,
    required: true,
  },
  current: {
    type: Number,
    required: true,
  },
},
  emits: ["update:current"],
  setup(__props, { emit }) {

const props = __props




const { total, pageSize, current } = toRefs(props);
console.log(
  "total",
  total.value,
  "pageSize",
  pageSize.value,
  "current",
  current.value === 0 ? 1 : current.value
);

function setCurrent(v: number) {
  emit("update:current", v);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(total) > _unref(pageSize))
      ? (_openBlock(), _createBlock(_unref(ElPagination), {
          key: 0,
          background: "",
          layout: "prev, pager, next",
          total: _unref(total),
          "page-size": _unref(pageSize),
          "current-page": _unref(current) === 0 ? 1 : _unref(current),
          "onUpdate:currentPage": setCurrent
        }, null, 8, ["total", "page-size", "current-page"]))
      : _createCommentVNode("", true)
  ]))
}
}

})