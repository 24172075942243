import { createApp } from "vue";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import "amfe-flexible";

// el-loading
import ElLoading from "element-plus/lib/components/loading";
// import "element-plus/theme-chalk/el-loading.css";
// import "element-plus/theme-chalk/el-loading.css";
import "element-plus/theme-chalk/base.css";

import App from "./App.vue";
import router from "./router";
import store, { storeKey } from "./store";

// dayjs
dayjs.locale("zh-cn");

const app = createApp(App);
app.use(store, storeKey);
app.use(router);
app.use(ElLoading);
app.mount("#app");
