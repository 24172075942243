import dayjs from "dayjs";
import { ColumnItem } from "@/type";
import { PageResult } from "./backend/type";
import { formatDatetime } from "@/util";
import { getProductList, getProductDetail } from "./backend/product";
import { getImgUrl } from "./backend/img";

export async function loadProductList(
  page = 0,
  limit = 10
): Promise<PageResult<ColumnItem>> {
  const { data } = await getProductList(page, limit);
  if (data.data != null) {
    const result = data.data.map((x) => ({
      id: x.id,
      // TODO
      columnid: "",
      title: x.name,
      title2: x.producttypename,
      img: x.imgid != null ? getImgUrl(x.imgid) : undefined,
    })) as PageResult<ColumnItem>;

    result.total = data.count;
    return result;
  }

  const result = [] as unknown as PageResult<ColumnItem>;
  result.total = 0;
  return result;
}

export async function loadProductDetail(id: string): Promise<ColumnItem> {
  const { data } = await getProductDetail(id);
  if (data.data != null) {
    const d = data.data;
    return {
      id: d.id,
      // TODO
      columnid: "",
      title: d.name,
      title2: d.producttypename,
      desc: d.comments,
      img: d.imgid != null ? getImgUrl(d.imgid) : undefined,
      time: formatDatetime(dayjs(d.createDate)),
      //toColumn: d.linkurl,
      content: d.context,
      countRead: d.clicktimes != null ? d.clicktimes : 0,
    };
  }
  return {
    title: "",
    countRead: 0,
  };
}
