import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { toRefs, Ref } from "vue";
import type { PartConfig, PartItem } from "@/type";
import ConfigPartSubList from "./ConfigPartSubList.vue";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
  partType: {
    type: Object,
    required: true,
  },
},
  emits: ["update"],
  setup(__props, { emit }) {

const props = __props




const config = toRefs(props).config as Ref<PartConfig>;
const partType = toRefs(props).partType as Ref<PartItem>;

function setConfig(c: PartConfig) {
  emit("update", c);
}

return (_ctx: any,_cache: any) => {
  return (_unref(partType).childType == 'list')
    ? (_openBlock(), _createBlock(ConfigPartSubList, {
        key: 0,
        class: "config-part-sub",
        "part-type": _unref(partType),
        config: _unref(config),
        onUpdate: setConfig
      }, null, 8, ["part-type", "config"]))
    : _createCommentVNode("", true)
}
}

})