import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7b25031a")
const _hoisted_1 = { class: "img-box" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text" }
_popScopeId()

import {
  toRefs,
  Ref,
  ref,
  onUnmounted,
  onMounted,
  watch,
} from "vue";
import type { ColumnItem } from "@/type";
import { useSmallScreen, useGoLink } from "@/hook";
import LinkBtn from "@/components/ui/LinkBtn.vue";


export default _defineComponent({
  props: {
  list: {
    type: Array,
    required: true,
  },
  type: {
    type: String,
    default: null,
  },
},
  setup(__props) {

const props = __props

const isSmallScreen = useSmallScreen();
const list = toRefs(props).list as Ref<Array<ColumnItem>>;
const { type } = toRefs(props);
const goLink = useGoLink(type);

const left = ref(0);
const timer = ref();
const autoPlayFn = () => {
  timer.value = setInterval(() => {
    if (list.value.length > 5) {
      if (left.value === -230 * (list.value.length - 5)) {
        left.value = 0;
      }
      left.value = left.value - 1;
    }
  }, 50);
};
autoPlayFn();

watch(
  () => {
    return list.value;
  },
  (newV, oldV) => {
    console.log("watch list.value", newV, oldV);
    if (newV.length !== 0) {
      for (let i = 0; i < 5; i++) {
        list.value.push(list.value[i]);
      }
      //   console.log("村级网点建设,镇级网点建设模块获取数据", list);
    }
  }
);
const mouseenter = () => {
  clearInterval(timer.value);
};
const mouseleave = () => {
  autoPlayFn();
};
onUnmounted(() => {
  clearInterval(timer.value);
});

return (_ctx: any,_cache: any) => {
  return (_unref(list))
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["pic-por", { ss: _unref(isSmallScreen) }]),
        onMouseleave: mouseleave,
        onMouseenter: mouseenter
      }, [
        _createElementVNode("div", {
          class: "pic-list",
          style: _normalizeStyle({ left: left.value + 'px' })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (i, index) => {
            return (_openBlock(), _createBlock(LinkBtn, {
              key: index,
              onClick: ($event: any) => (_unref(goLink)(i))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("img", {
                    src: i.img
                  }, null, 8, _hoisted_2)
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("span", null, _toDisplayString(i.title), 1)
                ])
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 128))
        ], 4)
      ], 34))
    : _createCommentVNode("", true)
}
}

})