import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withScopeId as _withScopeId } from "vue"

import { useSmallScreenCheck, useThemeInit, useLoading } from "@/hook";
import Loading from "@/components/ui/Loading.vue";

// 适配手机屏幕: 小屏幕检测

export default _defineComponent({
  setup(__props) {

useSmallScreenCheck();

// 监听store中的主题,如果发生了变化则修改本地主题
useThemeInit();

// 初始化加载主题和主页面配置, 加载完毕之前不显示页面
// 并行初始化加载
// initLoadTheme 请求接口获取主题,修改store中的主题
// initLoadMainConfig 请求接口获取页头页脚文字/页头背景图片,修改store中的页头页脚文字/页头背景图片
const loading = useLoading();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_unref(loading))
    ? (_openBlock(), _createBlock(Loading, {
        key: 0,
        loading: ""
      }))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}
}

})