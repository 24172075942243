// PC 版页面设计宽度 (px)
export const PAGE_WIDTH_PC = 1920;

// 手机版页面设计宽度 (px)
export const PAGE_WIDTH_SS = 720;

// 默认缩放比例  60%
export const ZOOM_DEFAULT = 0.6;

// 默认显示偏移位置 (px)
export const POS_DEFAULT = [234, 55];

// 鼠标滚轮滚动速度
export const SCROLL_SPEED = -0.6;
