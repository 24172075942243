import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-10770686")
const _hoisted_1 = { class: "layout-box" }
const _hoisted_2 = { class: "item-layout-box" }
const _hoisted_3 = { class: "time" }
_popScopeId()

import { toRefs, Ref } from "vue";
import type { PartConfig } from "@/type";
import { useSmallScreen, useGoLink } from "@/hook";
import { usePartLoad } from "@/hook/part";
import Loading from "@/components/ui/Loading.vue";
import LinkBtn from "@/components/ui/LinkBtn.vue";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props


const isSmallScreen = useSmallScreen();

const config = toRefs(props).config as Ref<PartConfig>;
const [loading, list] = usePartLoad(config, (list, len) => list.slice(0, len));

const goLink = useGoLink();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["part-list1", { ss: _unref(isSmallScreen) }])
  }, [
    (_unref(loading))
      ? (_openBlock(), _createBlock(Loading, {
          key: 0,
          loading: ""
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (i, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "item"
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(LinkBtn, {
              class: "title",
              onClick: ($event: any) => (_unref(goLink)(i))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(i.title), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]),
            _createElementVNode("div", _hoisted_3, "[" + _toDisplayString(i.time) + "]", 1)
          ])
        ]))
      }), 128))
    ])
  ], 2))
}
}

})