import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d43b89c4")
const _hoisted_1 = { class: "top-user" }
const _hoisted_2 = {
  key: 0,
  class: "username"
}
_popScopeId()

import { computed, toRefs } from "vue";
import { useRouter } from "vue-router";
// useUser使用User信息
import { useUser, useEdit, useSmallScreen } from "@/hook";
import { useStore } from "@/hook/store";
import TopNavBtn from "./TopNavBtn.vue";

interface BtnItem {
  icon?: string;
  text: string;
  onClick?: () => void;
}


export default _defineComponent({
  props: {
  enableEdit: {
    type: Boolean,
    default: false,
  },
},
  emits: ["edit"],
  setup(__props, { emit }) {

const props = __props




const isSmallScreen = useSmallScreen();

const { enableEdit } = toRefs(props);

const user = useUser();
const isEdit = useEdit();
// 手机上和编辑模式不显示编辑按钮, 未登录不显示编辑按钮
// TODO 编辑权限检查
const showEdit = computed(
  () =>
    user.value != null &&
    enableEdit.value &&
    !isSmallScreen.value &&
    !isEdit.value
);

const router = useRouter();

function clickBtn(i: BtnItem) {
  if (i.onClick != null) {
    i.onClick();
  }
}

const store = useStore();

function onLogout() {
  store.commit("user/SET_USER_INFO", null);
  // 退出登录后返回首页
  router.replace("/");
}

const btnList = computed(() => {
  if (user.value != null) {
    const result: Array<BtnItem> = [
      {
        text: "退出",
        onClick() {
          onLogout();
        },
      },
    ];
    if (showEdit.value) {
      result.push({
        text: "编辑页面",
        onClick() {
          emit("edit");
        },
      });
    }
    return result;
  }
  return [
    {
      icon: require("../../assets/icon/login.png"),
      text: "登录",
      onClick() {
        router.push("/login");
      },
    },
    // {
    //   icon: require("../../assets/icon/regist.png"),
    //   text: "注册",
    // },
  ] as Array<BtnItem>;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(user) != null)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_unref(user).username), 1))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(btnList), (i, index) => {
      return (_openBlock(), _createBlock(TopNavBtn, {
        key: index,
        icon: i.icon,
        text: i.text,
        onClick: ($event: any) => (clickBtn(i))
      }, null, 8, ["icon", "text", "onClick"]))
    }), 128))
  ]))
}
}

})