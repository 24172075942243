import { AxiosPromise } from "axios";
import request from "./util";
import { ResResult2, PageResultT } from "./type";

export interface ProductListResult {
  address: string;
  clicktimes: number;
  comesource?: string;
  comments?: string;
  context: string;
  counts: number;
  countss?: string;
  createBy: string;
  createDate: number;
  delFlag: number;
  fid?: string;
  fixtop: string;
  fname?: string;
  id: string;
  imgid: string;
  keysearchword?: string;
  limit?: string;
  model?: string;
  modelcounts?: string;
  modelid?: string;
  modelids?: string;
  modellist?: Array<string>;
  modelprice?: string;
  models?: string;
  name: string;
  orders: number;
  org1?: string;
  org1name?: string;
  org2?: string;
  org2name?: string;
  org3?: string;
  org3name?: string;
  orgfullname?: string;
  page?: string;
  price: number;
  prices?: string;
  producttypeid: string;
  producttypename: string;
  publishdate?: string;
  salemode: string;
  sourceid?: string;
  sourcename: string;
  spare1?: string;
  spare2?: string;
  spare3?: string;
  spare4?: string;
  spare5?: string;
  status: number;
  statuss: null;
  updateBy: string;
  updateDate: number;
}

export function getProductList(
  page: number,
  limit = 10
): AxiosPromise<PageResultT<ProductListResult>> {
  return request({
   //publicapi/list
    url: "/product/publicapi/list",
    method: "GET",
    params: {
      page: page + 1,
      limit,
    },
  });
}

export interface ProductDetailResult {
  address: string;
  clicktimes: number;
  comesource?: string;
  comments?: string;
  context: string;
  counts: number;
  countss?: string;
  createBy: string;
  createDate: number;
  delFlag: number;
  fid?: string;
  fixtop: string;
  fname?: string;
  id: string;
  imgid: string;
  keysearchword?: string;
  limit?: string;
  model?: string;
  modelcounts?: string;
  modelid?: string;
  modelids?: string;
  modellist?: Array<string>;
  modelprice?: string;
  models?: string;
  name: string;
  orders: number;
  org1?: string;
  org1name?: string;
  org2?: string;
  org2name?: string;
  org3?: string;
  org3name?: string;
  orgfullname?: string;
  page?: string;
  price: number;
  prices?: string;
  producttypeid: string;
  producttypename: string;
  publishdate?: string;
  salemode: string;
  sourceid?: string;
  sourcename: string;
  spare1?: string;
  spare2?: string;
  spare3?: string;
  spare4?: string;
  spare5?: string;
  status: number;
  statuss: null;
  updateBy: string;
  updateDate: number;
}

export function getProductDetail(
  id: string
): AxiosPromise<ResResult2<ProductDetailResult>> {
  return request({
    url: "/product/publicapi/detail",
    method: "GET",
    params: {
      id,
    },
  });
}
