// type目录下的index文件 引入并导出了 type目录下的主要接口配置
// import { ThemeConfig } from "@/type"; === import { ThemeConfig } from "@/type/theme";
import { ThemeConfig } from "@/type";
// 引入了默认主题 , 默认导出可以重新命名
import defaultTheme from "@/config/theme";
// 请求接口获取数据
import { getThemeConfig } from "./backend/config";

export async function loadTheme(): Promise<ThemeConfig> {
  // 发送请求获取样式
  const { data } = await getThemeConfig("1");
  // 如果接口返回了数据则使用if
  if (data.data != null) {
    const d = data.data;
    // Object.assign({}, defaultTheme,{}) 后面的对象会覆盖前面的对象的 同名字属性 保证数据不会缺失
    return Object.assign({}, defaultTheme, {
      name: d.name,
      mainColor: d.mainColor,
      footerColor: d.footerColor,
      footerTextColor: d.footerTextColor,
      pageColor: d.pageColor,
      pageColor2: d.pageColor2,
      textColor: d.textColor,
      textColor2: d.textColor2,
      textColor3: d.textColor3,
      topNavColor: d.topNavColor,
      topTitleColor: d.topTitleColor,
      topTitleColor2: d.topTitleColor2,
      topTitleBg: d.topTitleBg,
      //mainWidth: d.mainWidth,
      //defaultFontSize: d.defaultFontSize,
    });
  }
  // 如果接口没有返回数据则使用 return Object.assign({}, defaultTheme)
  return Object.assign({}, defaultTheme);
}
