import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withScopeId as _withScopeId } from "vue"

import { ref, toRefs, onMounted, Ref } from "vue";
import { useRouter } from "vue-router";
import type { PartConfig } from "@/type";
import { setPageTitle } from "@/util";
import { loadPartList } from "@/api/edit";
import FullBox from "@/components/layout/FullBox.vue";
import Loading from "@/components/ui/Loading.vue";
import PageEditor from "@/components/edit/PageEditor.vue";
import { saveConfig } from "@/api/edit";


export default _defineComponent({
  props: {
  columnid: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props


const { columnid } = toRefs(props);

const config: Ref<Array<PartConfig>> = ref([]);
const loading = ref(true);

onMounted(async () => {
  setPageTitle("页面编辑器");

  config.value = await loadPartList(columnid.value);
  loading.value = false;
});

const router = useRouter();

function onBack() {
  router.back();
}

async function onSave() {
  await saveConfig(config.value);
  // TODO
  onBack();
}

function onCancel() {
  // TODO
  onBack();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(FullBox, { class: "page" }, {
    default: _withCtx(() => [
      (loading.value)
        ? (_openBlock(), _createBlock(Loading, {
            key: 0,
            loading: ""
          }))
        : (_openBlock(), _createBlock(PageEditor, {
            key: 1,
            config: config.value,
            "onUpdate:config": _cache[0] || (_cache[0] = ($event: any) => (config.value = $event)),
            onSave: onSave,
            onCancel: onCancel
          }, null, 8, ["config"]))
    ]),
    _: 1
  }))
}
}

})