import { RouteRecordRaw } from "vue-router";
import Home from "@/views/home/index.vue";
import Edit from "@/views/edit/index.vue";
import Login from "@/views/login/index.vue";
import List from "@/views/list/index.vue";
import Article from "@/views/article/index.vue";
import Construction from "@/views/construction/index.vue";
import Download from "@/views/download/download.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Home,
    // 项目中好像根本就没有用到meta属性
    meta: {
      title: "首页",
    },
    // === 在页面中使用 this.$route.query.xxx 获取的参数相同
    props: (route) => ({
      columnid: route.query.columnid,
    }),
  },
  {
    path: "/edit",
    component: Edit,
    meta: {
      title: "页面编辑器",
    },
    props: (route) => ({
      columnid: route.query.columnid,
    }),
  },
  {
    path: "/login",
    component: Login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/list",
    component: List,
    meta: {
      title: "栏目",
    },
    props: (route) => ({
      columnid: route.query.columnid,
      type: route.query.type,
    }),
  },
  {
    path: "/article",
    component: Article,
    meta: {
      title: "文章",
    },
    props: (route) => ({
      id: route.query.id,
      type: route.query.type,
    }),
  },
  {
    path: "/construction",
    component: Construction,
    meta: {
      title: "体系建设",
    },
    // props: (route) => ({
    //   id: route.query.id,
    //   type: route.query.type,
    // }),
  },
  {
    path: "/download",
    component: Download,
    meta: {
      title: "下载",
    },
    // props: (route) => ({
    //   id: route.query.id,
    //   type: route.query.type,
    // }),
  },
];

export default routes;
