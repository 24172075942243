import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-77a2e3ee")
const _hoisted_1 = { class: "content" }
_popScopeId()

import { toRefs, Ref } from "vue";
import type { PartConfig } from "@/type";
import { useSmallScreen } from "@/hook";
import EditSelectBox from "@/components/edit/EditSelectBox.vue";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props


const isSmallScreen = useSmallScreen();

const config = toRefs(props).config as Ref<PartConfig>;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditSelectBox, {
    class: _normalizeClass(["part-box", { ss: _unref(isSmallScreen) }]),
    config: _unref(config)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ]),
    _: 3
  }, 8, ["class", "config"]))
}
}

})