import { ref, inject, computed, Ref, ComputedRef } from "vue";
import { Store } from "vuex";
import type { MainConfig, EditSelectHost } from "@/type";
import { loadMainConfig } from "@/api/edit";
import { State } from "@/store";
import { KEY_EDIT, KEY_EDIT_SELECT, KEY_EDIT_SELECT_CURRENT } from "./key";
import { useStore } from "./store";

export function useEdit(): Ref<boolean> {
  const isEdit = inject<Ref<boolean>>(KEY_EDIT, ref(false));
  return isEdit;
}

export function useEditSelect(): EditSelectHost {
  return inject<EditSelectHost>(KEY_EDIT_SELECT, {
    onSelect() {},
  });
}

export function useEditSelectCurrent(): Ref<number> {
  return inject<Ref<number>>(KEY_EDIT_SELECT_CURRENT, ref(0));
}

export function useMainConfig(): ComputedRef<MainConfig> {
  const store = useStore();
  return computed(() => store.getters["edit/main"]);
}

export async function initLoadMainConfig(store: Store<State>): Promise<void> {
  const mainConfig = await loadMainConfig();
  store.commit("edit/SET_MAIN", mainConfig);
}
