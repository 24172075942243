import { ref, provide, inject, onMounted, onBeforeUnmount, Ref } from "vue";
import { SMALL_SCREEN_WIDTH } from "@/config";
import { KEY_SMALL_SCREEN } from "./key";

// 适配手机小屏幕
export function useSmallScreen(): Ref<boolean> {
  const isSmallScreen = inject<Ref<boolean>>(KEY_SMALL_SCREEN, ref(false));
  return isSmallScreen;
}

export function useSmallScreenCheck(): void {
  const result = ref(false);
  provide(KEY_SMALL_SCREEN, result);

  function onResize() {
    const width = document.documentElement.clientWidth;
    if (width < SMALL_SCREEN_WIDTH) {
      result.value = true;
    } else {
      result.value = false;
    }
    // DEBUG
    console.log(`  [${width}] small screen = ${result.value}`);
  }

  onMounted(() => {
    window.addEventListener("resize", onResize);
    // 第一次检测
    onResize();
  });
  onBeforeUnmount(() => {
    window.removeEventListener("resize", onResize);
  });
}
