<!-- FullBox  全屏页面, 用于编辑器

  slot: (default)

-->

<template>
  <div class="full-box">
    <slot />
  </div>
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.full-box {
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}
</style>
