import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-47585178")
const _hoisted_1 = ["src"]
_popScopeId()

import { toRefs } from "vue";
import { useSmallScreen } from "@/hook";
import LinkBtn from "@/components/ui/LinkBtn.vue";


export default _defineComponent({
  props: {
  text: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    default: null,
  },
},
  emits: ["click"],
  setup(__props, { emit }) {

const props = __props




const isSmallScreen = useSmallScreen();

const { text, icon } = toRefs(props);

function onClick() {
  emit("click");
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LinkBtn, {
    class: _normalizeClass(["top-nav-btn", { ss: _unref(isSmallScreen) }]),
    onClick: onClick
  }, {
    default: _withCtx(() => [
      (_unref(icon) != null)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: _unref(icon)
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("span", null, _toDisplayString(_unref(text)), 1)
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})