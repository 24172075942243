import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-36206e6f")
const _hoisted_1 = { class: "layout-box" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "img-box" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "right" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "desc" }
const _hoisted_8 = { class: "publishdate" }
_popScopeId()

import { toRefs, Ref } from "vue";
import type { PartConfig } from "@/type";
import { useSmallScreen, useGoLink } from "@/hook";
import { usePartLoad } from "@/hook/part";
import Loading from "@/components/ui/Loading.vue";
import LinkBtn from "@/components/ui/LinkBtn.vue";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props


const isSmallScreen = useSmallScreen();

const config = toRefs(props).config as Ref<PartConfig>;

const [loading, list] = usePartLoad(config, (list, len) => list.slice(0, len));
// console.log("测试图片获取", list);

const goLink = useGoLink();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["part-list2", { ss: _unref(isSmallScreen) }])
  }, [
    (_unref(loading))
      ? (_openBlock(), _createBlock(Loading, {
          key: 0,
          loading: ""
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (i, index) => {
        return (_openBlock(), _createBlock(LinkBtn, {
          key: index,
          class: "item",
          onClick: ($event: any) => (_unref(goLink)(i))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  src: i.img
                }, null, 8, _hoisted_4)
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(i.title), 1),
              _createElementVNode("div", _hoisted_7, _toDisplayString(i.desc), 1),
              _createElementVNode("div", _hoisted_8, "[" + _toDisplayString(i.publishdate) + "]", 1)
            ])
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ])
  ], 2))
}
}

})