import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

import { ElMessage } from "element-plus";
import { useSmallScreen } from "@/hook";


export default _defineComponent({
  setup(__props) {

const isSmallScreen = useSmallScreen();
const ua = navigator.userAgent;

function dload(type: string) {
  // 判断环境
  if (ua.includes("Weixin")) {
    alert("微信浏览器暂不支持当前项目某些功能，请点击右上角由浏览器打开");
  } else {
    if (type !== "ios") {
      window.location.href =
        "https://www.feicheng.org.cn:8088/static/yicuntong.apk";
    } else {
      alert("IOS版本正在开发中");
    }
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["download", { ss: _unref(isSmallScreen) }])
  }, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (dload('an'))),
      class: "abtn"
    }, "安卓下载"),
    _createElementVNode("div", {
      onClick: _cache[1] || (_cache[1] = ($event: any) => (dload('ios'))),
      class: "abtn"
    }, "IOS下载")
  ], 2))
}
}

})