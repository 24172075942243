import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-47c36233")
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "header-content" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = { class: "title2" }
const _hoisted_5 = { class: "right" }
const _hoisted_6 = { class: "qrcodeBOX" }
const _hoisted_7 = { class: "qrcodeBOX" }
const _hoisted_8 = {
  key: 0,
  class: "nav-box"
}
const _hoisted_9 = { class: "nav-list" }
_popScopeId()

import {
  ref,
  computed,
  toRefs,
  onMounted,
  Ref,
} from "vue";
import QrcodeVue from "qrcode.vue";
import type { ColumnItem } from "@/type";
import { getArticleList } from "@/api/column";
import { useMainConfig, useSmallScreen, useGoLink } from "@/hook";
import LinkBtn from "@/components/ui/LinkBtn.vue";
import TopUser from "./TopUser.vue";


export default _defineComponent({
  props: {
  noBg: {
    type: Boolean,
    default: false,
  },
  showNav: {
    type: Boolean,
    default: true,
  },
  enableEdit: {
    type: Boolean,
    default: false,
  },
},
  emits: ["edit"],
  setup(__props, { emit }) {

const props = __props



//
onMounted(() => {
  //   const QRCode = require("qrcodejs2");
  //   const qrcode = new QRCode("qrcode", {
  //     width: 140, // 设置宽度，单位像素
  //     height: 140, // 设置高度，单位像素
  //     text: "https://www.feicheng.org.cn/#/download", // 设置二维码内容或跳转地址
  //   });
  //   const qrcodeTwo = new QRCode("qrcode", {
  //     width: 140, // 设置宽度，单位像素
  //     height: 140, // 设置高度，单位像素
  //     text: "https://www.feicheng.org.cn/", // 设置二维码内容或跳转地址
  //   });
});

const isShow = ref();
const isShow2 = ref();
function move(res: number) {
  if (res === 1) {
    isShow.value = true;
  } else {
    isShow2.value = true;
  }
}
function leave(res: number) {
  if (res === 1) {
    isShow.value = false;
  } else {
    isShow2.value = false;
  }
}

function onEdit() {
  emit("edit");
}

const { noBg, enableEdit } = toRefs(props);

const isSmallScreen = useSmallScreen();

const config = useMainConfig();
const headerText = computed(() => config.value.title);
const headerText2 = computed(() => config.value.title2);

const navList: Ref<Array<ColumnItem>> = ref([]);

onMounted(async () => {
  const columnid = config.value.topNavColumn;
  console.log(navList);
  // 获取导航列表
  navList.value = await getArticleList(columnid);
  // 修改导航列表中的体系建设
  navList.value.map((item) => {
    if (item.title === "体系建设") {
      item.newLink = "/construction";
    }
  });
  //
  //   console.log("navList.value", navList.value);
});

const goLink = useGoLink();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["top-nav", { ss: _unref(isSmallScreen) }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["header-box", { noBg: _unref(noBg) }])
    }, [
      (!_unref(noBg))
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "header-bg",
            src: _unref(config).topBg
          }, null, 8, _hoisted_1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h1", null, _toDisplayString(_unref(headerText)), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(headerText2)), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              onMousemove: _cache[0] || (_cache[0] = ($event: any) => (move(1))),
              onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (leave(1)))
            }, "亿村通下载", 32),
            _withDirectives(_createVNode(QrcodeVue, {
              class: "qcClass",
              value: "https://www.feicheng.org.cn/#/download"
            }, null, 512), [
              [_vShow, isShow.value === true]
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              onMousemove: _cache[2] || (_cache[2] = ($event: any) => (move(2))),
              onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (leave(2)))
            }, "手机端", 32),
            _withDirectives(_createVNode(QrcodeVue, {
              class: "qcClass",
              value: "https://www.feicheng.org.cn/"
            }, null, 512), [
              [_vShow, isShow2.value === true]
            ])
          ]),
          _createVNode(TopUser, {
            "enable-edit": _unref(enableEdit),
            onEdit: onEdit
          }, null, 8, ["enable-edit"])
        ])
      ])
    ], 2),
    (__props.showNav)
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(navList.value, (i, index) => {
              return (_openBlock(), _createBlock(LinkBtn, {
                key: index,
                class: "nav-item",
                onClick: ($event: any) => (_unref(goLink)(i))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(i.title), 1)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})