import { StoreOptions } from "vuex";
import { ThemeConfig } from "@/type";
import defaultTheme from "@/config/theme";

export interface State {
  theme: ThemeConfig;
}

export default <StoreOptions<State>>{
  namespaced: true,
  state: () => ({
    theme: defaultTheme,
  }),
  getters: {
    theme(state) {
      return state.theme;
    },
  },
  mutations: {
    SET(state, data: ThemeConfig) {
      state.theme = data;
    },
  },
};
