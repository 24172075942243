import { StoreOptions } from "vuex";
import { UserData } from "@/type";
import { API_HEADER_AUTH } from "@/api/config";
import agent from "@/api/backend/util";

export interface State {
  userData?: UserData;
}

// 用户登录后设置 token
function setToken(token: string | undefined) {
  if (token != null) {
    agent.defaults.headers.common[API_HEADER_AUTH] = token;
  } else {
    agent.defaults.headers.common[API_HEADER_AUTH] = null;
  }
}

export default <StoreOptions<State>>{
  namespaced: true,
  state: () => ({}),
  getters: {
    isLogin(state): boolean {
      return !!state.userData;
    },
    userInfo(state): UserData | undefined {
      return state.userData;
    },
    token(state): string | undefined {
      if (state.userData != null) {
        return state.userData.token;
      }
    },
  },
  mutations: {
    SET_USER_INFO(state, data: UserData | undefined) {
      state.userData = data;
      if (data != null) {
        setToken(data.token);
      } else {
        setToken(undefined);
      }
    },
  },
};
