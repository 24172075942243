import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

import { ref, computed, provide, toRefs, onMounted } from "vue";
import { KEY_EDIT, KEY_EDIT_ZOOM } from "@/hook/key";
import { ZOOM_DEFAULT, POS_DEFAULT, SCROLL_SPEED } from "./def";


export default _defineComponent({
  props: {
  pageWidth: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props


const { pageWidth } = toRefs(props);

// 显示缩放
const zoom = ref(ZOOM_DEFAULT);
// 偏移位置
const pos = ref(POS_DEFAULT);

provide(KEY_EDIT_ZOOM, zoom);

const contentBoxStyle = computed(() => ({
  width: `${pageWidth.value}px`,
  zoom: zoom.value,
}));

const contentWrapperStyle = computed(() => ({
  top: `${pos.value[1]}px`,
  left: `${pos.value[0]}px`,
}));

// 上下滚动查看页面内容
function onScroll(e: WheelEvent) {
  const offset = e.deltaY * SCROLL_SPEED;

  const newPos = Array.from(pos.value);
  newPos[1] += offset;
  pos.value = newPos;
}

// 自动计算页面缩放与位置
const root = ref(null);

onMounted(() => {
  const e = root.value as unknown as HTMLDivElement;
  const boxWidth = e.clientWidth;
  const contentWidth = pageWidth.value * zoom.value;

  const posX = (boxWidth - contentWidth) / 2;
  const newPos = Array.from(pos.value);
  newPos[0] = posX;
  pos.value = newPos;
});

// 告诉内部组件, 现在是编辑模式
provide(KEY_EDIT, ref(true));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref: root,
    class: "view-box",
    onMousewheel: onScroll
  }, [
    _createElementVNode("div", {
      class: "content-wrapper",
      style: _normalizeStyle(_unref(contentWrapperStyle))
    }, [
      _createElementVNode("div", {
        class: "content-box",
        style: _normalizeStyle(_unref(contentBoxStyle))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 4)
    ], 4)
  ], 544))
}
}

})