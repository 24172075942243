import { Ref } from "vue";
import { useRouter } from "vue-router";
import type { ColumnItem } from "@/type";
import { getColumnInfo } from "@/api/column";

// 跳转到栏目 (根据显示类型)
export function useGoList(): (id: string) => void {
  const router = useRouter();

  return function goList(id: string) {
    getColumnInfo(id).then((i) => {
      // i 是请求返回结果 , 只有农家特产有值 , 值为4
      router.push(`/list?columnid=${id}&type=${i.type != null ? i.type : 1}`);
    });
  };
}

// 通用点击跳转逻辑
export function useGoLink(type?: Ref<string>): (i: ColumnItem) => void {
  const router = useRouter();
  const goList = useGoList();

  return function goLink(i: ColumnItem) {
    //
    console.log("通用跳转[/hook/nav/useGoLink],本次跳转参数:", i);

    // 注释内容用需要配置跳转参数 , 以用来区分跳转逻辑
    //
    if (i.newLink !== undefined) {
      router.push(i.newLink);
    } else if (i.salemode !== undefined) {
      router.push(`/article?id=${i.productid}&type=productDetail`);
    } else {
      if (i.toColumn != null) {
        // TODO :columnid 跳转到自定义布局页面
        // 跳转到某栏目
        if (i.toColumn.startsWith("/")) {
          // 直接路由跳转
          router.push(i.toColumn);
        } else if (i.toColumn.startsWith("http")) {
          // 跳转 URL 链接
          window.open(i.toColumn, "_blank");
        } else {
          // 跳转到列表页面
          goList(i.toColumn);
        }
      } else {
        // 跳转显示文章详情
        // 文章详情页调用了面包屑导航
        // [views/article/index.vue]
        let p = `/article?id=${i.id}`;
        if (type != null && type.value != null) {
          p += `&type=${type.value}`;
        }
        router.push(p);
      }
    }
  };
}
