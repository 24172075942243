import { AxiosPromise } from "axios";
import request from "./util";
import { ResResult2 } from "./type";

export interface ThemeConfigData {
  id: string;
  themeid: string;
  name: string;
  mainColor: string;
  footerColor: string;
  footerTextColor: string;
  pageColor: string;
  pageColor2: string;
  textColor: string;
  textColor2: string;
  textColor3: string;
  topNavColor: string;
  topTitleColor: string;
  topTitleColor2: string;
  topTitleBg: string;
  mainWidth: number;
  defaultFontSize: number;
  title: string;
  title2: string;
  footer: string;
  topBg: string;
  topNavColumn: string;
  footerNavColumn: string;
  homeConfigData: string;
}

export function getThemeConfig(
  id: string
): AxiosPromise<ResResult2<ThemeConfigData>> {
  return request({
    url: `/themeconfig/publicapi/detail?id=${id}`,
    method: "GET",
  });
}

export function saveThemeConfig(data: ThemeConfigData): AxiosPromise<void> {
  return request({
    url: "/themeconfig/api/save",
    method: "POST",
    data,
  });
}
