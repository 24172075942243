import { InjectionKey } from "vue";
import { createStore, Store } from "vuex";

import user, { State as StateUser } from "./user";
// 主题,默认值 = defaultTheme
import theme, { State as StateTheme } from "./theme";
import edit, { State as StateEdit } from "./edit";

// index.ts为默认引入文件 , 所以在index.ts中做了集合导出
export interface State extends StateUser, StateTheme, StateEdit {}

export const storeKey: InjectionKey<Store<State>> = Symbol();

export default createStore<State>({
  mutations: {},
  actions: {},
  getters: {},
  modules: {
    user,
    theme,
    edit,
  },
});
