import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-fc276400")
const _hoisted_1 = { class: "layout-box" }
_popScopeId()

import { onMounted } from "vue";
import { setPageTitle } from "@/util";
import PageBox from "@/components/layout/PageBox.vue";
import TopNav from "@/components/base/TopNav.vue";
import Footer from "@/components/base/Footer.vue";
import LoginBox from "./LoginBox.vue";


export default _defineComponent({
  setup(__props) {

onMounted(() => {
  setPageTitle("登录");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageBox, { class: "page" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TopNav, {
          "no-bg": "",
          showNav: false
        }),
        _createVNode(LoginBox)
      ]),
      _createVNode(Footer)
    ]),
    _: 1
  }))
}
}

})