import { StoreOptions } from "vuex";
import { MainConfig } from "@/type";

export interface State {
  mainConfig?: MainConfig;
}

export default <StoreOptions<State>>{
  namespaced: true,
  state: () => ({}),
  getters: {
    main(state) {
      return state.mainConfig;
    },
  },
  mutations: {
    SET_MAIN(state, data: MainConfig) {
      state.mainConfig = data;
    },
  },
};
