import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-60ec5c86")
const _hoisted_1 = { class: "img-box" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "text" }
_popScopeId()

import { toRefs, Ref } from "vue";
import type { ColumnItem } from "@/type";
import { useSmallScreen, useGoLink } from "@/hook";
import LinkBtn from "@/components/ui/LinkBtn.vue";


export default _defineComponent({
  props: {
  list: {
    type: Array,
    required: true,
  },
  type: {
    type: String,
    default: null,
  },
  width: {
    type: String,
  },
  height: {
    type: String,
  },
  minWidth: {
    type: String,
  },
  minHeight: {
    type: String,
  },
},
  setup(__props) {

const props = __props


const isSmallScreen = useSmallScreen();

const list = toRefs(props).list as Ref<Array<ColumnItem>>;
// console.log("村级网点建设,镇级网点建设模块获取数据", list);

const { type } = toRefs(props);

const goLink = useGoLink(type);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["pic-list", { ss: _unref(isSmallScreen) }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (i, index) => {
      return (_openBlock(), _createBlock(LinkBtn, {
        key: index,
        onClick: ($event: any) => (_unref(goLink)(i)),
        style: _normalizeStyle({ width: __props.width, height: __props.height, minWidth: __props.minWidth, minHeight: __props.minHeight })
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("img", {
              src: i.img
            }, null, 8, _hoisted_2)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", null, _toDisplayString(i.title), 1)
          ])
        ]),
        _: 2
      }, 1032, ["onClick", "style"]))
    }), 128))
  ], 2))
}
}

})