// provide(key)

// 检测是否在编辑模式中
export const KEY_EDIT = Symbol("key-edit");
// 检测当前是否是手机小屏幕
export const KEY_SMALL_SCREEN = Symbol("key-small-screen");

// 用于编辑时选中一块
export const KEY_EDIT_SELECT = Symbol("key-edit-select");
// 编辑时选中的当前块
export const KEY_EDIT_SELECT_CURRENT = Symbol("key-edit-select-current");
// 编辑缩放率 (用于框内还原缩放)
export const KEY_EDIT_ZOOM = Symbol("key-edit-zoom");
