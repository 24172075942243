import { AxiosPromise } from "axios";
import request from "./util";
import { ResResult } from "./type";

export interface LoginData {
  appKey: string;
  appSecret: string;
  code: string;
  codeuuid: string;
}

export interface LoginResult {
  fname: string;
  uname: string;
  userid: string;
  hname: string;
  token: string;
}

export interface CodeResult {
  data: any;
  imgdata: string;
  codeuuid: string;
}

export function appLogin1(
  data: LoginData
): AxiosPromise<ResResult<LoginResult>> {
  return request({
    url: "/portalLogin",
    method: "POST",
    data,
  });
}

export function getCode(): AxiosPromise<CodeResult> {
  return request({
    url: "/getJsonCode",
    method: "GET",
    data: new Date().getTime(),
  });
}

export function getLoginCodeByToken(res: string): AxiosPromise<CodeResult> {
  return request({
    url: "/api/getLoginCodeByToken",
    method: "GET",
    headers: { Authorization: res },
  });
}
