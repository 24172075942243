import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-65a3fb57")
const _hoisted_1 = ["src"]
_popScopeId()

import { toRefs, Ref } from "vue";
// vant
import VanSwipe from "vant/es/swipe";
import "vant/es/swipe/style";
import VanSwipeItem from "vant/es/swipe-item";
import "vant/es/swipe-item/style";

import type { PartConfig } from "@/type";
import { useSmallScreen } from "@/hook";
import { usePartLoad } from "@/hook/part";
import EditSelectBox from "@/components/edit/EditSelectBox.vue";


export default _defineComponent({
  props: {
  config: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props


const isSmallScreen = useSmallScreen();

const config = toRefs(props).config as Ref<PartConfig>;
const [loading, list] = usePartLoad(config);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(EditSelectBox, {
    class: _normalizeClass(["part-slide", { ss: _unref(isSmallScreen) }]),
    config: _unref(config)
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(VanSwipe), {
        class: "swipe-box",
        autoplay: 3000,
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(list), (i, index) => {
            return (_openBlock(), _createBlock(_unref(VanSwipeItem), { key: index }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "swipe-img",
                  src: i.img
                }, null, 8, _hoisted_1)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class", "config"]))
}
}

})